import React, { useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import TabsComponent from '../components/tabs';
import { ReactComponent as DeleteIcon } from '../images/delete_row.svg'; 
import Button from '../components/button';
import { useNavigationPrompt } from '../functions/navigationHooks';
import ExportModal from '../components/exportModal';
import { TableExportData } from '../components/exportModal';
import { DocumentApi } from '../functions/api/document';
import toast from 'react-hot-toast';

// interface定義
interface AppData {
  appid: number;
  appname: string;
  description: string;
}

interface ConnectorData {
  connectorid: number;
  sid: number;
  tid: number;
  relationship: string;
  keyfield: string;
  referencefield: string;
}


interface RequirementData {
  appid: number;
  rid: number;
  name: string;
  description: string;
  how: string;
}

const AppDesign = () => {
  const [appData, setAppData] = useState<AppData[]>([]);
  const [initialAppData, setInitialAppData] = useState<AppData[]>([]);
  const [connectorData, setConnectorData] = useState<ConnectorData[]>([]);
  const [initialConnectorData, setInitialConnectorData] = useState<ConnectorData[]>([]);
  const [requirementData, setRequirementData] = useState<RequirementData[]>([]);
  const [initialRequirementData, setInitialRequirementData] = useState<RequirementData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [points, setPoints] = useState<string[]>([]);
  const [initialPoints, setInitialPoints] = useState<string[]>([]);
  const { user, currentProject } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate()
  const [currentTabIndex, setCurrentTabIndex] = useState(0); // 現在のタブを管理
  const [showExportModal, setShowExportModal] = useState(false);

  // 編集
  const [isEditable, setIsEditable] = useState(false);
  const handleExportClick = () => {
    setShowExportModal(true);
  };

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement | null) => {
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  
  const getExportData = (): TableExportData => {
    const headers: string[] = [];
    const rows: string[][] = [];
  
    // アプリテーブルのヘッダーとデータを追加
    headers.push('アプリテーブル: アプリID', 'アプリ名', '説明');
    appData.forEach(row => {
      rows.push([row.appid.toString(), row.appname, row.description]);
    });
  
    // 空行を追加して次のテーブルと区切る
    rows.push([]);
  
    // アプリ間連携テーブルのヘッダーとデータを追加
    headers.push(
      'アプリ間連携テーブル: 連携ID', 
      'ソースアプリID', 
      'ターゲットアプリID', 
      '関係性', 
      'キーフィールド', 
      '参照フィールド'
    );
    connectorData.forEach(row => {
      rows.push([
        row.connectorid.toString(),
        row.sid.toString(),
        row.tid.toString(),
        row.relationship,
        row.keyfield,
        row.referencefield
      ]);
    });
  
    // 空行を追加して次のテーブルと区切る
    rows.push([]);
  
    // 要求機能テーブルのヘッダーとデータを追加
    headers.push('要求機能テーブル: アプリID', '機能ID', '名前', '説明', '実装方法');
    requirementData.forEach(row => {
      rows.push([
        row.appid.toString(),
        row.rid.toString(),
        row.name,
        row.description,
        row.how
      ]);
    });
    // 空行を追加して次のテーブルと区切る
    rows.push([]);

    headers.push('考慮点');
    points.forEach(row=>{
      rows.push([
        row
      ])
    })

    const name = 'アプリ設計書'
  
    return { headers, rows, name };
  };

  const addPoint = () => {
    setPoints([...points, '']);
  };

  const updatePoint = (index: number, value: string) => {
    const updatedPoints = [...points];
    updatedPoints[index] = value;
    setPoints(updatedPoints);
  };

  const deletePoint = (index: number) => {
    setPoints(points.filter((_, i) => i !== index));
  };


  const handleLock = async() =>{
    const documentApi = new DocumentApi()

    if (!currentProject.requirements.contents.app_design.id) {
      toast.error("アプリ設計書が見つかりません")
      return;
    }

    else if (!user) {
      toast.error("不明なユーザーです")
      return;
    }

    const response = await documentApi.lock(currentProject.requirements.contents.app_design.id, user?.id, currentProject.id, user?.organization_id);

    if (response !== null) {
        console.log('app design is locked')
        setIsEditable(true);
    }

  }
  const handleUpdate = async () => {
    
        // ドキュメントのアップデートAPIリクエスト
        const documentApi = new DocumentApi();

        if (!currentProject.requirements.contents.app_design.id) {
          toast.error("機能要件書が見つかりません")
          return;
        }
    
        else if (!user) {
          toast.error("不明なユーザーです");
          return;
        }

        const updateData = {
          テーブル一覧: {
            アプリテーブル: {
              headers: ['appid', 'appname', 'description'],
              rows: appData.map((row) => [row.appid, row.appname, row.description]),
            },
            アプリ間連携テーブル: {
              headers: ['connectorid', 'sid', 'tid', 'relationship', 'keyfield', 'referencefield'],
              rows: connectorData.map((row) => [
                row.connectorid,
                row.sid,
                row.tid,
                row.relationship,
                row.keyfield,
                row.referencefield,
              ]),
            },
            要求機能テーブル: {
              headers: ['appid', 'rid', 'name', 'description', 'how'],
              rows: requirementData.map((row) => [
                row.appid,
                row.rid,
                row.name,
                row.description,
                row.how,
              ]),
            },
          },
          考慮点: points,
        };

        const response = await documentApi.update(currentProject.requirements.contents.app_design.id, user?.id, currentProject.id, user?.organization_id, updateData);
    
        if (response !== null) {
          setIsEditable(false);
          setInitialAppData(appData);
          setInitialConnectorData(connectorData);
          setInitialPoints(points);
          setInitialRequirementData(requirementData)
        } 
    };

    const handleUnlock = async () =>{

      const documentApi = new DocumentApi()
  
      if (!currentProject.requirements.contents.app_design.id) {
        toast.error("アプリ設計書が見つかりません");
        return; 
      }
  
      else if (!user) {
        toast.error("不明なユーザーです");
        return;
      }
  
      const response = await documentApi.unlock(currentProject.requirements.contents.app_design.id, user?.id, currentProject.id, user?.organization_id);
  
      if (response !== null) {
        console.log('app design is unlocked')
      } 
    }
  

  // 編集中の場合、ナビゲーションをブロック
  useNavigationPrompt(
    isEditable,
    '編集内容が保存されていません。ページを離れてもよろしいですか？',
    handleUpdate,
    handleUnlock
  );

  const updateData = (
    rowIndex: number,
    columnId: string,
    value: string | number,
    tabIndex: number
  ) => {
    let updatedData: Record<string, any>[];
    if (tabIndex === 0) {
      updatedData = [...appData];
      updatedData[rowIndex][columnId] =
        typeof updatedData[rowIndex][columnId] === 'number' ? Number(value) : value;
      setAppData(updatedData as AppData[]);
    } else if (tabIndex === 1) {
      updatedData = [...connectorData];
      updatedData[rowIndex][columnId] =
        typeof updatedData[rowIndex][columnId] === 'number' ? Number(value) : value;
      setConnectorData(updatedData as ConnectorData[]);
    } else if (tabIndex === 2) {
      updatedData = [...requirementData];
      updatedData[rowIndex][columnId] =
        typeof updatedData[rowIndex][columnId] === 'number' ? Number(value) : value;
      setRequirementData(updatedData as RequirementData[]);
    }
  };

  const columns1: ColumnDef<object>[] = [
    { accessorKey: 'appid', header: 'アプリID' },
    { accessorKey: 'appname', header: 'アプリ名' },
    { accessorKey: 'description', header: '説明'},
    {
      accessorKey: 'delete', 
      header: '', 
      cell: ({ row }) => (
        isEditable ? (
          <button
          onClick={() => deleteRow(row.index,0)}
          style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
        >
          <DeleteIcon width={16} height={16} />
        </button>
        ) : null
      )
    },
  ];

  const columns2: ColumnDef<object>[] = [
    { accessorKey: 'connectorid', header: '連携ID' },
    { accessorKey: 'sid', header: 'ソースアプリID' },
    { accessorKey: 'tid', header: 'ターゲットアプリID' },
    { accessorKey: 'relationship', header: '関係性' },
    { accessorKey: 'keyfield', header: 'キーフィールド'},
    { accessorKey: 'referencefield', header: '参照フィールド'},
    {
      accessorKey: 'delete', 
      header: '', 
      cell: ({ row }) => (
        isEditable ? (
          <button
          onClick={() => deleteRow(row.index,1)}
          style={{cursor: 'pointer', justifyContent: 'flex-end' }}
        >
          <DeleteIcon width={16} height={16} />
        </button>
        ) : null
      )
    },
  ];

  const columns3: ColumnDef<object>[] = [
    { accessorKey: 'appid', header: 'アプリID' },
    { accessorKey: 'rid', header: '機能ID' },
    { accessorKey: 'name', header: '名前' },
    { accessorKey: 'description', header: '説明' },
    { accessorKey: 'how', header: '実装方法' },
    {
      accessorKey: 'delete', 
      header: '', 
      cell: ({ row }) => (
        isEditable ? (
          <button
          onClick={() => deleteRow(row.index,2)}
          style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
        >
          <DeleteIcon width={16} height={16} />
        </button>
        ) : null
      )
    },
  ];

  const deleteRow = (rowIndex: number, tabIndex: number) => {
    if (tabIndex === 0) {
      setAppData(appData.filter((_, index) => index !== rowIndex));
    } else if (tabIndex === 1) {
      setConnectorData(connectorData.filter((_, index) => index !== rowIndex));
    } else if (tabIndex === 2) {
      setRequirementData(requirementData.filter((_, index) => index !== rowIndex));
    }
  };

  const addRow = (tabIndex: number) => {
    if (tabIndex === 0) {
      setAppData([...appData, { appid: 0, appname: '', description: '' }]);
    } else if (tabIndex === 1) {
      setConnectorData([...connectorData, { connectorid: 0, sid: 0, tid: 0, relationship: '', keyfield: '', referencefield: '' }]);
    } else if (tabIndex === 2) {
      setRequirementData([...requirementData, { appid: 0, rid: 0, name: '', description: '', how: '' }]);
    }
  };

  useEffect(() => {
    if (currentProject?.requirements.contents.app_design?.id == null) {
      toast.error('アプリ設計書は生成されていません。');
      return navigate('/project-top');
    }

    const fetchAppDesign = async () => {
      setIsLoading(true)
      if (currentProject.requirements.contents.app_design.id === null) {

        toast.error("アプリ設計書が見つかりません")
        setIsLoading(false)
        navigate("project-top")
        return ;
      }
      const documentApi = new DocumentApi();

      const response = await documentApi.get(currentProject.requirements.contents.app_design.id, currentProject.id);

      if (response !== null) {
        const rawAppDesignData = response.content;

        const parsedAppDesignData = JSON.parse(rawAppDesignData);
        console.log(parsedAppDesignData);

        // データを整形して AppData, ConnectorData, RequirementData 型に変換
        const formattedAppData: AppData[] = parsedAppDesignData['テーブル一覧']['アプリテーブル']['rows'].map((item: any) => ({
          appid: item[0],
          appname: item[1],
          description: item[2],
        }));

        const formattedConnectorData: ConnectorData[] = parsedAppDesignData['テーブル一覧']['アプリ間連携テーブル']['rows'].map(
          (item: any) => ({
            connectorid: item[0],
            sid: item[1],
            tid: item[2],
            relationship: item[3],
            keyfield: item[4],
            referencefield: item[5],
          })
        );

        const formattedRequirementData: RequirementData[] = parsedAppDesignData['テーブル一覧']['要求機能テーブル']['rows'].map(
          (item: any) => ({
            appid: item[0],
            rid: item[1],
            name: item[2],
            description: item[3],
            how: item[4]
          })
        );
        const PointsData = parsedAppDesignData['考慮点']

        // ステートに設定
        setAppData(formattedAppData);
        setInitialAppData(JSON.parse(JSON.stringify(formattedAppData)));
        setConnectorData(formattedConnectorData);
        setInitialConnectorData(JSON.parse(JSON.stringify(formattedConnectorData)));
        setRequirementData(formattedRequirementData);
        setInitialRequirementData(JSON.parse(JSON.stringify(formattedRequirementData)));
        setPoints(PointsData);
        setInitialPoints(JSON.parse(JSON.stringify(PointsData)));
        setIsLoading(false); // ローディング完了
      }
      else {
        setIsLoading(false); // ローディング終了
        navigate('/project-top');
      }
    };

    fetchAppDesign(); // コンポーネントのマウント時にデータをフェッチ
  
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isEditable) {
        if (!currentProject.requirements.contents.app_design.id) {
          toast.error("アプリ設計書が見つかりません");
          return;
        }
  
        if (!user) {
          toast.error("不明なユーザーです");
          return;
        }
  
        const documentApi = new DocumentApi();
  
        // ロック解除処理
        documentApi.unlock(currentProject.requirements.contents.app_design.id, user?.id, currentProject.id, user?.organization_id);
  
        // カスタムメッセージ（古いブラウザでは表示される）
        event.preventDefault();
        event.returnValue = ''; // Chrome用: 空文字を指定
      }
    };
  
    // beforeunload イベントの登録
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      // クリーンアップ
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isEditable, user, currentProject]);

  if(isLoading){
    return <div>ロード中...</div>;
  }
  const toggleEditMode = () => {
    if (isEditable) {
        handleUpdate(); // 保存処理を実行
    } else {
        handleLock();
    }
  };

  const handleCancel = async() => {

    if (!currentProject.requirements.contents.app_design.id) {
      toast.error("アプリ設計書が見つかりません");
      return;
    }

    if (!user?.id) {
      toast.error("ユーザーが見つかりません");
      return;
    }

    const documentApi = new DocumentApi();

    const response = await documentApi.unlock(currentProject.requirements.contents.app_design.id, user?.id, currentProject.id, user?.organization_id);

    if (response !== null) {
      console.log("unlock app design");
      setAppData(initialAppData);
      setConnectorData(initialConnectorData);
      setRequirementData(initialRequirementData);
      setPoints(initialPoints);
      setIsEditable(false)
    } 

  } 

  return (
    <div>
      <h1>アプリ設計書</h1>
      {showExportModal && (
        <ExportModal
          onClose={() => setShowExportModal(false)}
          exportData={{ type: 'table', exportData: getExportData(), name: 'app-design' }}
        />

      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
      {isEditable && (
          <Button onClick={handleCancel} styleType='white' label={"キャンセル"} />
      )}
        <Button onClick={toggleEditMode} styleType="blue-generation-each-document" label={isEditable ? '保存' : '編集'}/>
        <Button label='エクスポート' onClick={handleExportClick} styleType='blue' disabled={isEditable}/>
        </div>
      <TabsComponent
        tabTitles={['アプリテーブル', 'アプリ間連携テーブル', '要求機能テーブル']}
        dataSets={[appData, connectorData, requirementData]} // データセットをタブに渡す
        columns={[columns1, columns2, columns3]} // 各タブに対応する列定義
        updateData={updateData}
        isEditable={isEditable}
        onTabChange={setCurrentTabIndex} // タブ変更時にインデックスを更新
      />
    {isEditable && (
      
      <div style={{ marginTop: '10px' }}>
        <Button label="行を追加" onClick={() => addRow(currentTabIndex)} styleType="blue" />
      </div>
      )}
<h2>考慮点</h2>
{isEditable ? (
  <>
    {points.map((point, index) => (
      <div
        key={index}
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          width: '100%',
        }}
      >
      <div
      style={{
        flex: 1,
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '5px',
      }}
    >
        <textarea
          value={point}
          onChange={(e) => updatePoint(index, e.target.value)}
          onInput={(e) => adjustTextareaHeight(e.target as HTMLTextAreaElement)} // 高さ調整
          style={{
            width: '100%',
            fontSize: '16px',
            resize: 'none',
            backgroundColor: 'transparent',
          }}
        />
        </div>
        <button
          onClick={() => deletePoint(index)}
          style={{
            cursor: 'pointer',
            marginLeft: '10px',
          }}
        >
          <DeleteIcon width={16} height={16} />
        </button>
      </div>
    ))}
    <Button label="考慮点を追加" onClick={addPoint} styleType="blue" />
  </>
) : (
  <ul>
    {points.map((point, index) => (
      <li
        key={index}
        style={{
          padding: '8px'
        }}
      >
        {point}
      </li>
    ))}
  </ul>
)}
    </div>
  );
};

export default AppDesign;