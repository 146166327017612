export const projectTop = [
  {name: 'トップ', link: '/project-top', icon: 'top'},
  // {name: '単語登録', link: '/vocabulary', icon: 'document'},
  {name: '議事録', link: '/summary', icon: 'document'},
]
export const requirementItems = [
  {name: 'システム概要書', link: '/system-proposal', icon: 'document'},
  {name: '機能要件書', link: '/functional-requirement', icon: 'table'},
  {name: '非機能要件書', link: '/non-functional-requirement', icon: 'table'},
  {name: '業務フロー図', link: '/work-flow', icon: 'chart'},
  {name: 'プラグイン提案書', link: '/plugin-proposal', icon: 'table'},
];

export const designItems = [
  {name: 'アプリ設計書', link: '/app-design', icon: 'document'},
  {name: 'フィールド設計書', link: '/field-design', icon: 'table'},
  {name: 'システム構成図', link: '/system-architecture', icon: 'chart'},
  {name: 'ER図', link: '/entity-relation', icon: 'chart'},
]

export const developmentItems = [
  {name: 'カスタマイズ仕様書', link: '/', icon: 'document'},
  {name: 'サンプルアプリ', link: '/', icon: 'kintone-app'}
];
