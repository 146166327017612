import React from 'react';
import styles from './index.module.scss';

interface ButtonProps {
  label: string;             // ボタンのラベル
  onClick?: () => void;      // クリック時のイベントハンドラ
  type?: 'button' | 'submit' | 'reset';  // ボタンのタイプ（デフォルトは 'button'）
  disabled?: boolean;        // ボタンが無効化されているかどうか
  styleType?: string;  // カスタムスタイルのタイプ
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  type = 'button',
  disabled = false,
  styleType = 'primary',
}) => {
  return (
    <button
      className={`${styles.button} ${styles[styleType]}`}  // スタイルを動的に設定
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
