import { ERROR_MESSAGES } from "./errorMessages";
import { azureLoginUri } from "../config/apiConfig";

export function ErrorBoundary (statusCode: number) {
  if (statusCode === 401) {
    window.location.href = azureLoginUri;
    return ERROR_MESSAGES[statusCode];
  }else{
  // エラーメッセージを返す
    return ERROR_MESSAGES[statusCode] || `不明なエラー(errorCode: ${statusCode})`;
}}