import { commonHeader, endpoints } from "../../config/apiConfig";
import { InviteUser, UpdateOrganization } from "../../interface/organization";
import { ErrorBoundary } from "../../errors/errorBoundary";
import toast from "react-hot-toast";

export class OrganizationApi {
    private getRequestInfo (method: string, requestBody?: object): RequestInit {
        return {
            method: method,
            headers: commonHeader,
            credentials: 'include',
            keepalive: true,
            ...{
                body: JSON.stringify(requestBody)
            }
        };
    }

    private getRequestBody(baseBody: object, extraBody?: object): object {
        // ベースとなる共通データと、メソッドごとの追加データをマージ
        return { ...baseBody, ...extraBody };
    }

    async update(updateData: UpdateOrganization): Promise<Response | null>{
        const updateOrganization = endpoints.updateOrganization;

        try{
        const requestInfo = this.getRequestInfo("POST", updateData);

        const requestBody = this.getRequestBody(requestInfo);

        const response = await fetch(updateOrganization, requestBody);

        if (response.ok) {
            toast.success("保存に成功")
            return response
        }
        else {
            const errorMessage = ErrorBoundary(response.status);
            toast.error(errorMessage)
            return null
        }
        }catch (error) {
            console.log(error)
            toast.error("予期しないエラー")
            return null;
        }

    }

    async get(organization_id: string): Promise<Response | null>{
        const getOrganization = endpoints.getOrganization(organization_id);

        try{
        const requestInfo = this.getRequestInfo("GET");

        const requestBody = this.getRequestBody(requestInfo);

        const response = await fetch(getOrganization, requestBody);
        if (response.ok) {
            console.log("Get organization request is succeeded")
            return response
        }
        else {
            const errorMessage = ErrorBoundary(response.status);
            toast.error(errorMessage)
            return null
        }
        }catch (error) {
            console.log(error)
            toast.error('予期しないエラー');
            return null;
        }
    }
    async invite(inviteData: InviteUser): Promise<Response | null> {
        const inviteUser = endpoints.inviteUserToOrganization;

        try{
        const requestInfo = this.getRequestInfo("POST", inviteData);

        const requestBody = this.getRequestBody(requestInfo);

        const response = await fetch(inviteUser, requestBody);
        if (response.ok) {
            console.log("Invite user to organization request is succeeded")
            toast.success("招待に成功")
            return response
        }
        else {
            const errorMessage = ErrorBoundary(response.status);
            toast.error(errorMessage)
            return null
        }
        }catch (error) {
            console.log(error)
            toast.error("予期しないエラー")
            return null;
        }
    }
}