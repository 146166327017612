import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Document } from "../../interface/document";
import { dateToString } from "../../functions/datetime";
import DocumentView from "../documentView";
import { DocumentApi } from "../../functions/api/document";
import toast from "react-hot-toast";


const GenerationHistoryDetail = () => {

    const [currentData, setCurrentData] = useState<Document>(); // APIから取得したデータを格納
    const [isLoading, setIsLoading] = useState(true); // ローディング状態の管理
    const [selectedData, setSelectedData] = useState<Document | null>(null); // URLに含まれるID
    const { currentProject, user } = useSelector((state: RootState) => state.auth);
    const { selectedId } = useParams<{ selectedId: string }>(); // URLからIDを取得
    const navigate = useNavigate();
    const documentApi = new DocumentApi();

    useEffect(() => {
      setIsLoading(true);
      const fetchComparableData = async () => {
        if (!user || !currentProject) {
          return navigate("/signin")
        }
        if (!currentProject?.requirements.contents.system_proposal?.id) {
          toast.error('システム概要書は生成されていません');
          return navigate('/project-top');
        }
        // 現在のデータを取得
        const response = await documentApi.get(currentProject.requirements.contents.system_proposal.id, currentProject.id);
        if(response !== null){
          console.log(response);
          setCurrentData(response); // APIから取得したデータをセット
        }
        
        if (selectedId) {
          const responseDataById = await documentApi.get(selectedId, currentProject.id);
          if(responseDataById !== null){
            console.log(responseDataById);
            setSelectedData(responseDataById); // APIから取得したデータをセット
          }
        }
        setIsLoading(false);
      };
  
      fetchComparableData();
      setIsLoading(false);
    }, [currentProject, selectedId, navigate]);

    if (isLoading) {
      return <div>データをロード中です...</div>;
    }

    if (!currentData) {
      return <div>データを取得できませんでした。</div>
    }

    const handleGoBack = () => {
        navigate(-1);
    };
    const documentTitle = selectedData && selectedData.created_at ? `システム概要書 ${dateToString(selectedData.created_at)}` : 'システム概要書';
    

    return (
        <div className={styles.history} style={{position: "relative"}}>
            <div className={styles.header}>
                <div className={styles.header__navi}>
                    <p>システム概要書</p>
                    <p>＞</p>
                    <p>生成履歴</p>
                    <p>＞</p>
                    <p>生成履歴 詳細</p>
                </div>
                <div className={styles.header__title}>
                    <ArrowBackIcon className={styles.icon} onClick={handleGoBack} />
                    <h2>{documentTitle}</h2>
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.past}>
                  <div className={styles.pastContent}>
                    <div className={styles.pastVer}>バージョン</div>
                    <DocumentView formData={selectedData} comparisonData={currentData} />
                  </div>
                </div>
                <div className={styles.current}>
                  <div className={styles.currentContent}>
                    <div className={styles.currentVer}>現在のバージョン</div>
                    <DocumentView formData={currentData} />
                  </div>
                </div>
            </div>
        </div>
    )
};

export default GenerationHistoryDetail;
