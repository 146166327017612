import React, {useState} from 'react';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ProjectApi } from '../../functions/api/project';
import toast from 'react-hot-toast';

interface DeleteProjectProps {
    onClose: () => void;
    projectName: string;
    projectId:string;
}

const DeleteProjectModal: React.FC<DeleteProjectProps> = ({onClose, projectName, projectId}) => {
    const navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.auth);
    const [isLoading, setIsLoading] = useState(false);

    const handleDeleteProject = async() =>{
        setIsLoading(true);

        if (user?.id == null){
            toast.error('ユーザーが見つかりませんでした');
            return null
        }

        const projectApi = new ProjectApi();

        const response = await projectApi.delete(projectId, user?.id, user?.organization_id);

        if (response !== null) {
            setIsLoading(false);
            onClose();
            navigate('/')
        }
        else {
            setIsLoading(false);
        }
        
    }
        return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>
            <h2>プロジェクトの削除</h2>
            <h4>プロジェクト名： <strong>{projectName}</strong></h4>
            <div className={styles.modalWarning}>
            <span className={styles.warningIcon}>⚠️</span>
            <p>
            ※この操作は取り消せません <br />
            これまで生成したコンテンツを復元することはできません
            </p>
            </div>
            <div className={styles.actions}>
            <button className={styles.cancelButton} onClick={onClose} disabled={isLoading}>
                キャンセル
            </button >
            <button className={styles.deleteButton} onClick={handleDeleteProject} disabled={isLoading}>
            削除
            </button>
            </div>
            </div>
            </div>
        </div>
        </div>
        );

    }

export default DeleteProjectModal;
