import React, { useState } from 'react';
import styles from './index.module.scss';
import close from '../../images/close.svg';
import documentIcon from '../../images/documentIcon__onProjectCreated.svg';
import flowChart from '../../images/flowChart.svg';
import Button from '../button';
import SelectionModal from '../importSelectionModal';

interface ModalProps {
    label: string;
    isOpen: boolean;
    onClose: () => void;
}

const OnProjectCreatedModal: React.FC<ModalProps> = ({ label, isOpen, onClose }) => {
    const [isSelectionOpen, setIsSelectionOpen] = useState<boolean>(false);
    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <div className={styles.modalTitle}>
                    <h2>{label}</h2>
                    <div className={styles.closeButton} onClick={onClose}>
                        <img src={close} alt="" />
                    </div>
                </div>

                <div className={styles.content}>
                    <img src={documentIcon} alt="document icon" />
                    <h4>議事録やヒアリングシートをアップロードして開発を進めましょう！</h4>
                    <p>アップロードが完了するとシステム概要書が自動生成され、それに伴い工程別に要件定義書を次々と生成できます。</p>
                    <img src={flowChart} alt="flowChart image" />
                    <div className={styles.button}>
                        <Button label={'アップロードへ'} styleType="blue" onClick={() => setIsSelectionOpen(true)} ></Button>
                    </div>
                    {isSelectionOpen && <SelectionModal onClose={() => setIsSelectionOpen(false)}/>}
                </div>
            </div>
        </div>
    );
};

export default OnProjectCreatedModal;