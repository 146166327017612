import React, { useEffect, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import TableContent from '../components/tableContent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store';
import Button from '../components/button';
import { TableExportData } from '../components/exportModal';
import ExportModal from '../components/exportModal';
import { useNavigationPrompt } from '../functions/navigationHooks';
import { ReactComponent as DeleteIcon } from '../images/delete_row.svg'; 
import { DocumentApi } from '../functions/api/document';
import toast from 'react-hot-toast';

interface PluginProposalData {
  fid: string;
  必要機能: string,
  プラグイン名: string,
  機能一覧: string,
  価格情報: string,
  会社名: string,
  サマリー: string
  マッチ度: string
  URL: string
}

export const PluginProposalHeaders = Object.keys({
  fid: '',
  必要機能: '',
  プラグイン名: '',
  機能一覧: '',
  価格情報: '',
  会社名: '',
  サマリー: '',
  マッチ度: '',
  URL: '',
}) as Array<keyof PluginProposalData>;

const Plugin = () => {
  const [data, setData] = useState<PluginProposalData[]>([]);
  const [initialData, setInitialData] = useState<PluginProposalData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { currentProject,user } = useSelector((state: RootState) => state.auth);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate()
  const [showExportModal, setShowExportModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const toggleEditMode = () => {
    if (isEditable) {
        handleUpdate(); // 保存処理を実行
    } else {
        handleLock();
    }
  };

  const handleLock = async() =>{
    const documentApi = new DocumentApi()

    if (!currentProject.requirements.contents.plugin_proposal.id) {
      toast.error("プラグイン提案書が見つかりません")
      return;
    }

    else if (!user) {
      toast.error("不明なユーザーです")
      return;
    }

    const response = await documentApi.lock(currentProject.requirements.contents.plugin_proposal.id, user?.id, currentProject.id, user?.organization_id);

    if (response !== null) {
        console.log('plugin proposal is locked')
        setIsEditable(true);
    }

  }

  const handleUpdate = async () => {
        if (!currentProject.requirements.contents.plugin_proposal.id) {
          toast.error("プラグイン提案書が見つかりません")
          setIsLoading(false)
          return navigate('project-top')
        }

        else if (!user) {
          toast.error("不明なユーザーです")
          return;
        }

        const rows = data.map((row) => 
          PluginProposalHeaders.map((header) => row[header] || '')
        );

        const updateData = {
          PluginProposalHeaders,
          rows
        };

        // ドキュメントのアップデートAPIリクエスト
        const documentApi = new DocumentApi();
        const response = await documentApi.update(currentProject.requirements.contents.plugin_proposal.id, user?.id, currentProject.id, user?.organization_id, updateData);
    
        if (response !== null) {
          console.log('plugin proposal is unlocked')
          setIsEditable(false);
          setInitialData(data)
        }     
    };
    const handleUnlock = async () =>{

      const documentApi = new DocumentApi()
  
      if (!currentProject.requirements.contents.plugin_proposal.id) {
        toast.error("プラグイン提案書が見つかりません")
        return
      }
  
      else if (!user) {
        toast.error("不明なユーザーです")
        return;
      }
  
      const response = await documentApi.unlock(currentProject.requirements.contents.plugin_proposal.id, user?.id, currentProject.id, user?.organization_id);
  
      if (response !== null) {
        console.log('plugin proposal is unlocked')
      } 
    }
  // 編集中の場合、ナビゲーションをブロック
  useNavigationPrompt(
    isEditable,
    '編集内容が保存されていません。ページを離れてもよろしいですか？',
    handleUpdate,
    handleUnlock
  );

  const addNewRow = () => {
    const newRow: PluginProposalData = PluginProposalHeaders.reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {} as PluginProposalData);

    setData([...data, newRow]);
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isEditable) {
        if (!currentProject.requirements.contents.plugin_proposal.id) {
          toast.error("プラグイン提案書が見つかりません");
          return;
        }
  
        if (!user) {
          toast.error("不明なユーザーです");
          return;
        }
  
        const documentApi = new DocumentApi();
  
        // ロック解除処理
        documentApi.unlock(currentProject.requirements.contents.plugin_proposal.id, user?.id, currentProject.id, user?.organization_id);
  
        // カスタムメッセージ（古いブラウザでは表示される）
        event.preventDefault();
        event.returnValue = ''; // Chrome用: 空文字を指定
      }
    };
  
    // beforeunload イベントの登録
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      // クリーンアップ
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isEditable, user, currentProject]);
    
  const handleExportClick = () => {
    setShowExportModal(true);
  };

  const getExportData = (): TableExportData => {
    const name = 'プラグイン提案書'
    const rows = data.map((row) => PluginProposalHeaders.map((header) => row[header] || ''));
    const headers = PluginProposalHeaders
    return { headers, rows, name };
  };

  const updateData = (rowIndex: number, columnId: keyof PluginProposalData, value: string) => {
    const updatedData = [...data];
    updatedData[rowIndex][columnId] = value;
    setData(updatedData);
  };
  const sortAndFilterData = (data: PluginProposalData[]) => {
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.必要機能]) acc[item.必要機能] = [];
      acc[item.必要機能].push(item);
      return acc;
    }, {} as Record<string, PluginProposalData[]>);
  
    return Object.values(groupedData)
      .map(group => {
        group.sort((a, b) => parseFloat(b.マッチ度) - parseFloat(a.マッチ度));
        return group.map((item, index) => ({
          ...item,
          必要機能: index === 0 ? item.必要機能 : ''
        }));
      })
      .flat();
  };

  const columns: ColumnDef<PluginProposalData>[] = [
    ...PluginProposalHeaders.map((header) => ({
      accessorKey: header,
      header: header,
    })),
    {
      accessorKey: 'delete',
      header: '',
      cell: ({ row }) =>
        isEditable ? (
          <button
            onClick={() => deleteRow(row.index)}
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
          >
            <DeleteIcon width={16} height={16} />
          </button>
        ) : null,
    },
  ];

  const deleteRow = (rowIndex: number) => {
    const updatedData = data.filter((_, index) => index !== rowIndex);
    setData(updatedData); // 状態を更新
  };


  useEffect(()=>{
    if (!!currentProject?.requirements.contents.functional_requirement.id && !currentProject?.requirements.contents.plugin_proposal.id)  {
      toast.error('実装方法にプラグインが記載されていなかったため、プラグイン提案書は生成されていません');
      navigate('/project-top');
      return;
    }

    const fetchPluginProposal = async()=>{
      if (!currentProject.requirements.contents.plugin_proposal.id) {
        toast.error("プラグイン提案書が見つかりません")
        navigate("/project-top");
        return;
      }

      const documentApi = new DocumentApi();

      const response = await documentApi.get(currentProject.requirements.contents.plugin_proposal.id, currentProject.id);

      if (response !== null) {
      const rawManagementFieldData = response.content

      const parsedManagementFieldData = JSON.parse(rawManagementFieldData)
      const pluginProposalData = parsedManagementFieldData['rows']
      console.log(pluginProposalData)
      const formattedData: PluginProposalData[] = pluginProposalData.map((item: any) => ({
        fid: item[0] || '',
        必要機能: item[1] || '',
        プラグイン名: item[2] || '',
        機能一覧: item[3] || '',
        価格情報: item[4] || '',
        会社名: item[5] || '',
        サマリー: item[6] || '',
        マッチ度: item[7]?.toString() || '',
        URL: item[8] || ''
      }));

      const sortAndFilteredData = sortAndFilterData(formattedData);

      setData(sortAndFilteredData);
      setInitialData(JSON.parse(JSON.stringify(sortAndFilteredData)));
      setIsLoading(false)

    }
    else {
      toast.error('プラグイン提案書の取得に失敗しました');
      setIsLoading(false); // ローディング終了
      navigate('/project-top')

    }
  }
    fetchPluginProposal()
  },[])


  const handleCancel = async() => {

    if (!currentProject.requirements.contents.plugin_proposal.id) {
      toast.error("プラグイン提案書が見つかりません");
      return;
    }

    if (!user?.id) {
      toast.error("ユーザーが見つかりません");
      return;
    }

    const documentApi = new DocumentApi();

    const response = await documentApi.unlock(currentProject.requirements.contents.plugin_proposal.id, user?.id, currentProject.id, user?.organization_id);

    if (response !== null) {
      console.log("unlock plugin proposal");
      setData(initialData)
      setIsEditable(false)
    } 

  } 

  if(isLoading){
    return <div>ロード中...</div>;
  }
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h1>プラグイン提案書</h1>
        {/* インポートボタン */}
        {showExportModal && (
        <ExportModal
          onClose={() => setShowExportModal(false)}
          exportData={{ type: 'table', exportData: getExportData(), name: 'plugin-proposal' }}
        />

      )}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
        <Button label='エクスポート' onClick={handleExportClick} styleType='blue-generation-each-document' disabled={isEditable}/>
        {isEditable && (
          <Button onClick={handleCancel} styleType='white' label={"キャンセル"} />
      )}
        <Button onClick={toggleEditMode} styleType="blue" label={isEditable ? '保存' : '編集'}/>
        </div>
      <TableContent data={data} columns={columns} updateData={updateData} isEditable={isEditable} />
      {isEditable && <Button label="行を追加" onClick={addNewRow} styleType="blue" />}
    </div>
  );
};

export default Plugin;
