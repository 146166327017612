import React, {useState} from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { ColumnDef } from '@tanstack/react-table';
import TableContent from '../tableContent';
import styles from './index.module.scss';

interface TabsComponentProps {
  tabTitles: string[];
  dataSets: object[][];
  columns: Array<ColumnDef<object>[]>;
  updateData: (rowIndex: number, columnId: string, value: string | number, tabIndex: number) => void;
  isEditable: boolean;
  onTabChange: (tabIndex: number) => void; // タブ変更のコールバック
}

const TabsComponent = ({ dataSets, columns, tabTitles, updateData, isEditable, onTabChange }: TabsComponentProps) =>{
  const [currentTab, setCurrentTab] = useState(0);
  const handleTabClick = (index: number) => {
    setCurrentTab(index);
    onTabChange(index); // 親にタブ変更を通知
  };
  return (
    <Tabs className={styles['tab']}>
      <TabList className={styles['tab-list']}>
        {tabTitles.map((title, index) => (
          <Tab
            key={index}
            className={styles['tab-list-name']}
            selectedClassName={styles['tab-list-name--selected']}
            onClick={() => handleTabClick(index)}
          >
            {title}
          </Tab>
        ))}
      </TabList>

      {dataSets.map((data, index) => (
        <TabPanel key={index} className={styles['custom-tab-panel']}>
          <TableContent
            data={dataSets[currentTab]}
            columns={columns[currentTab]}
            updateData={(rowIndex, columnId, value) =>
              updateData(rowIndex, columnId, value, currentTab)
            }
            isEditable={isEditable}
          />
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default TabsComponent;
