import React,{useEffect} from 'react';
import ProjectList from '../components/projectList';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentProject } from '../slices/auth';
import { AppDispatch, RootState } from '../store';
import toast from 'react-hot-toast';


const Home = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { status } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(setCurrentProject(null));
  }, [dispatch]);

  return (
    <div>
      {status === "idle" && <ProjectList />}
    </div>
  );
};

export default Home;
