import React from 'react';
import styles from './index.module.scss';
import { InviteUserInfo } from '../../interface/organization';

interface ModalProps {
    members: InviteUserInfo[];
    setMembers: React.Dispatch<React.SetStateAction<InviteUserInfo[]>>;
}

const InviteExternalUser: React.FC<ModalProps> = ({ members, setMembers }) => {

    const handleEmailChange = (index: number, email: string) => {
        const updateMembers = [...members];
        updateMembers[index].email = email;
        setMembers(updateMembers);
    };

    const handleRoleChange = (index: number, role: string) => {
        const updatedMembers = [...members];
        updatedMembers[index].role = role;
        setMembers(updatedMembers);
    };

    const addMember = () => {
        setMembers([...members, { email: '', role: '' }]);
    };

    const removeMember = (index: number) => {
        const updatedMembers = [...members];
        updatedMembers.splice(index, 1);
        setMembers(updatedMembers);
    };

    return (
        <div className={styles.memberList}>
              {members.map((member, index) => (
                <div key={index} className={styles.memberInputRow}>
                  <div className={styles.emailField}>
                    <label>メールアドレス</label>
                    <input
                      type="email"
                      placeholder="メールアドレス"
                      value={member.email}
                      onChange={(e) => handleEmailChange(index, e.target.value)}
                      required
                    />
                  </div>
                  
                  <div className={styles.roleField}>
                    <label>権限</label>
                    <select
                      value={member.role}
                      onChange={(e) => handleRoleChange(index, e.target.value)}
                      required
                    >
                      <option value="" disabled>選択</option>
                      <option value="admin">管理者</option>
                      <option value="manager">プロジェクト管理者</option>
                      <option value="member">ユーザー</option>
                    </select>
                  </div>
                  
                  {index > 0 && (
                    <button className={styles.removeButton} type="button" onClick={() => removeMember(index)}>
                      ー
                    </button>
                  )}
                </div>
              ))}
              
              <button className={styles.addButton} type="button" onClick={addMember}>
                <span>+</span> 追加
              </button>
        </div>
    )
};

export default InviteExternalUser;