import React, { useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useNavigate} from 'react-router-dom';
import { useSelector, useDispatch  } from 'react-redux';
import { RootState } from '../store';
import ExportModal from '../components/exportModal';
import TableContent from '../components/tableContent';
import Button from '../components/button';
import { TableExportData } from '../components/exportModal';
import { Requirements } from '../interface/requirements';
import { setCurrentProjectRequirements } from '../slices/auth';
import { useNavigationPrompt } from '../functions/navigationHooks';
import { ReactComponent as DeleteIcon } from '../images/delete_row.svg'; 
import { RequirementsApi } from '../functions/api/requirements';
import { DocumentApi } from '../functions/api/document';
import toast from 'react-hot-toast';

interface RowData {
  fid: number | string;
  要求機能: string;
  必要機能: string;
  誰が: string;
  どこで: string;
  何をする: string;
  実装方法: string;
}

function createRowData(
  fid: number | string,
  要求機能: string,
  必要機能: string,
  誰が: string,
  どこで: string,
  何をする: string,
  実装方法: string,
) : RowData {
  const rowData: RowData = {fid, 要求機能, 必要機能, 誰が, どこで, 何をする, 実装方法};

  return rowData;
}

const FuncRequirement = () => {
  const [data, setData] = useState<RowData[]>([]);
  const [initialData, setInitialData] = useState<RowData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const { user, currentProject } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [showExportModal, setShowExportModal] = useState(false);

  // 編集
  const [isEditable, setIsEditable] = useState(false);
  // データ更新の関数
  const updateData = (rowIndex: number, columnId: keyof typeof data[0], value: string) => {
    const updatedData = [...data];
    // フィールドの型に応じて適切に処理
  if (columnId === 'fid') {
    updatedData[rowIndex][columnId] = parseInt(value, 10); // 数値フィールドには数値を代入
  } else {
    updatedData[rowIndex][columnId] = value; // それ以外は文字列として扱う
  }
    setData(updatedData);
  };

  const columns: ColumnDef<typeof data[0]>[] = [
    { accessorKey: 'fid', header: 'FID' },
    { accessorKey: '要求機能', header: '要求機能' },
    { accessorKey: '必要機能', header: '必要機能' },
    { accessorKey: '誰が', header: '誰が' },
    { accessorKey: 'どこで', header: 'どこで' },
    { accessorKey: '何をする', header: '何をする' },
    { accessorKey: '実装方法', header: '実装方法' },
    {
      accessorKey: 'delete', 
      header: '', 
      cell: ({ row }) => (
        isEditable ? (
          <button
          onClick={() => deleteRow(row.index)}
          style={{ background: 'none', border: 'none', cursor: 'pointer' }}
        >
          <DeleteIcon width={16} height={16} />
        </button>
        ) : null
      )
    },
  ];

  const getExportData = (): TableExportData => {
    const name = '機能要件'
    const headers = ['fid', '要求機能', '必要機能', '誰が', 'どこで', '何をする', '実装方法'];
    const rows = data.map((row) =>
      headers.map((header) => row[header as keyof RowData]?.toString() || '')
    );
    return { headers, rows, name };
  };

  const toggleEditMode = () => {
    if (isEditable) {
        handleUpdate(); // 保存処理を実行
    } else {
        handleLock();
    }
  };

  const addNewRow = () => {
    const newRow: RowData = createRowData(
      data.length + 1, // 新しいFID
      '', '', '', '', '', '',
    );
    setData([...data, newRow]);
  };

  const handleLock = async() =>{
    const documentApi = new DocumentApi()

    if (!currentProject.requirements.contents.functional_requirement.id) {
      toast.error("機能要件書が見つかりません")
      return;
    }

    else if (!user) {
      toast.error("不明なユーザーです")
      return;
    }

    const response = await documentApi.lock(currentProject.requirements.contents.functional_requirement.id, user?.id, currentProject.id, user?.organization_id);

    if (response !== null) {
        console.log('functional requirement is locked')
        setIsEditable(true);
    }

  }
  const handleUpdate = async () => {
    const documentApi = new DocumentApi();

    if (!currentProject.requirements.contents.functional_requirement.id) {
      toast.error("機能要件書が見つかりません")
      return;
    }

    else if (!user) {
      toast.error("不明なユーザーです")
      return;
    }

    // アップデートするデータの整形
    const headers = ['fid', '要求機能', '必要機能', '誰が', 'どこで', '何をする', '実装方法'];
    const rows = data.map((row) => headers.map((header) => row[header as keyof RowData] || ''));

    const updateData = {
        headers,
        rows
    };

    const response = await documentApi.update(currentProject.requirements.contents.functional_requirement.id, user?.id, currentProject.id, user?.organization_id, updateData);
    
    if (response !== null) {
      setIsEditable(false);
    } 
  };

  const handleUnlock = async () =>{

    const documentApi = new DocumentApi()

    if (!currentProject.requirements.contents.functional_requirement.id) {
      toast.error("機能要件書が見つかりません");
      return;
    }

    else if (!user) {
      toast.error("不明なユーザーです");
      return;
    }

    const response = await documentApi.unlock(currentProject.requirements.contents.functional_requirement.id, user?.id, currentProject.id, user?.organization_id);

    if (response !== null) {
      console.log('functional requirement is unlocked')
      setInitialData(data)
    } 
  }

  // 編集中の場合、ナビゲーションをブロック
  useNavigationPrompt(
    isEditable,
    '編集内容が保存されていません。ページを離れてもよろしいですか？',
    handleUpdate,
    handleUnlock
  );

  const handleExportClick = () => {
    setShowExportModal(true);
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isEditable) {
        if (!currentProject.requirements.contents.functional_requirement.id) {
          toast.error("機能要件書が見つかりません");
          return;
        }
  
        if (!user) {
          toast.error("不明なユーザーです");
          return;
        }
  
        const documentApi = new DocumentApi();
  
        // ロック解除処理
        documentApi.unlock(currentProject.requirements.contents.functional_requirement.id, user?.id, currentProject.id, user?.organization_id);
  
        // カスタムメッセージ（古いブラウザでは表示される）
        event.preventDefault();
        event.returnValue = ''; // Chrome用: 空文字を指定
      }
    };
  
    // beforeunload イベントの登録
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      // クリーンアップ
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isEditable, user, currentProject]);

  const handleNextGenerate = async () => {
    setIsGenerating(true);
    const requirementsApi = new RequirementsApi();
    if(!user) {
      toast.error("ユーザーが見つかりません");
      return;
    }
    const response = await requirementsApi.generateArchitecture(user.id, currentProject.id, user.organization_id)
    if (response !== null) {
      const requirements: Requirements = response;
      dispatch(setCurrentProjectRequirements(requirements));
    }
    setIsGenerating(false);
    }

  useEffect(() => {
    if (currentProject?.requirements.contents.functional_requirement?.id == null) {
      toast.error('機能要件書は生成されていません。');
      return navigate('/project-top');
    }

    const fetchFunctionalRequirement = async() => {

      if (!currentProject.requirements.contents.functional_requirement.id) {
        toast.error("機能要件書が見つかりません")
        return; 
            }

      const documentApi = new DocumentApi();

      const response = await documentApi.get(currentProject.requirements.contents.functional_requirement.id, currentProject.id);

      if (response !== null) {
        const rawFunctionalRequirement = response.content;
        const parsedFunctionalRequirement: any = JSON.parse(rawFunctionalRequirement);
        console.log("機能要件書：", parsedFunctionalRequirement);
        const functionalRequirementRows: [] = parsedFunctionalRequirement.rows

        let rowsData: RowData[] = []
        functionalRequirementRows.forEach(row => {
          const rowData = createRowData(row[0], row[1], row[2], row[3], row[4], row[5], row[6]);
          rowsData.push(rowData);
        });

        setData(rowsData);
        setInitialData(JSON.parse(JSON.stringify(rowsData))); // 深いコピー
        setIsLoading(false)
      } 
      else {
        setIsLoading(false); // ローディング終了
        navigate('/project-top');
      }
    };

    fetchFunctionalRequirement();
  }, []);

  if(isLoading){
    return <div>ロード中...</div>;
  }

  const deleteRow = (rowIndex: number) => {
    const updatedData = data.filter((_, index) => index !== rowIndex);
    setData(updatedData); // 状態を更新
  };

  
  const handleCancel = async() => {

    if (!currentProject.requirements.contents.functional_requirement.id) {
      toast.error("機能要件書が見つかりません");
      return;
    }

    if (!user?.id) {
      toast.error("ユーザーが見つかりません");
      return;
    }

    const documentApi = new DocumentApi();

    const response = await documentApi.unlock(currentProject.requirements.contents.functional_requirement.id, user?.id, currentProject.id, user?.organization_id);

    if (response !== null) {
      console.log("unlock functional requirement");
      setIsEditable(false)
      setData(initialData)
    } 

  } 

  return (
    <div>
      <h1>機能要件</h1>
      {showExportModal && (
        <ExportModal
          onClose={() => setShowExportModal(false)}
          exportData={{ type: 'table', exportData: getExportData() , name:'functional-requirement'}}
        />

      )}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>

          <Button label={isGenerating ? '生成中...' : '基本設計書を生成'} styleType="blue-generation-each-document" onClick={handleNextGenerate} disabled={isGenerating || isEditable} />
          {isEditable && (
          <Button onClick={handleCancel} styleType='white' label={"キャンセル"} />
      )}
          <Button onClick={toggleEditMode} styleType="blue-generation-each-document" label={isEditable ? '保存' : '編集'}/>
          
          <Button label='エクスポート' onClick={handleExportClick} styleType='blue' disabled={isEditable}/>
        </div>
      <TableContent data={data} columns={columns} updateData={(rowIndex, columnId, value) => {if (columnId !== 'fid') {updateData(rowIndex, columnId, value);}}}isEditable={isEditable}/>
      {isEditable && <Button label="行を追加" onClick={addNewRow} styleType="blue" />}
    </div>
  );
};

export default FuncRequirement;
