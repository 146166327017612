import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useReactTable, ColumnDef, getCoreRowModel } from '@tanstack/react-table';
import InviteOrganizationModal from '../inviteOrganizationModal';
import Modal from '../modal';
import Button from '../button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { UpdateOrganization } from '../../interface/organization';
import { useNavigate } from 'react-router-dom';
import { OrganizationApi } from '../../functions/api/organization';

interface ListComponentProps<TData> {
  data: TData[];
  columns: ColumnDef<TData>[];
  //updateData: (rowIndex: number, columnId: keyof TData, value: string | number) => void;
  tabTitle: string;
}

const ListContent = <TData,>({
  data,
  columns,
  //updateData,
  tabTitle,
}: ListComponentProps<TData>) => {

  const [name, setName] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const { role } = useSelector((state: RootState) => state.auth);
  const { user } = useSelector((state: RootState) => state.auth);
  const [isSaving, setIsSaving] = useState<boolean>(false); // リクエスト中かどうか
  const isAdmin = false;
  const list = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const navigate = useNavigate();

  // モーダルの開閉を管理するステート
  const [isModalOpen, setModalOpen] = useState(false);

  // 編集ボタンのクリックでモーダルを開く
  const openModal = () => setModalOpen(true);

  // モーダルを閉じる関数
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    console.log('Data passed to ListContent:', data);
    const organizationName = (data as any[]).find((item) => item.property === '組織名')?.description || '';
    const organizationUrl = (data as any[]).find((item) => item.property === 'URL')?.description || '';
    setName(organizationName);
    setUrl(organizationUrl);
  }, [data]);
  
  const handleUpdateOrganization = async () => {
    if (!user) {
      return null;
    }

    setIsSaving(true); // 保存処理中にする

    const organizationApi = new OrganizationApi();

    const updateData: UpdateOrganization = {
      name: name,
      url: url,
      organization_id: user.organization_id
    };
    
    const response = await organizationApi.update(updateData);
    
      if (response !== null) {
        navigate(0); 
        setIsSaving(false); 
        closeModal();
      } else {
        setIsSaving(false);
      }

  }

  // タブに応じたモーダルコンテンツ
  const getModalContent = () => {
    switch (tabTitle) {
      case '詳細情報':
        return (
          <div>
            <form className={styles.content}>
              <div>
                <label>
                  組織名<span style={{color: 'red', marginLeft: '4px', fontWeight: 'bold' }}>*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>
                  会社のURL（任意）
                </label>
                <textarea
                  placeholder="詳細を入力してください。空欄可。"
                  name="description"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
              </div>
            </form>
            <div className={styles.buttonContainer}>
              <Button label="キャンセル" styleType="white" onClick={closeModal} />
              <Button label="保存" styleType="blue" onClick={handleUpdateOrganization} disabled={!name.trim() || isSaving} />
            </div>
          </div>

        );
      case 'メンバー':
        return <div><InviteOrganizationModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} /></div>;
      default:
        return <div>デフォルトの編集コンテンツ</div>;
    }
  };

  // 特定のタブ（メンバー）かどうかを判定
  const isMemberTab = tabTitle === 'メンバー';

  return (
    <div className={styles.listContent}>
      <div className={styles.title}>
        <h3>{tabTitle}</h3>
        {role?.currentOrganizationRole === "admin" && <Button label="編集" styleType="blue" onClick={openModal} />}
      </div>

      {isMemberTab ? (
        <ul className={styles.memberList}>
          {list.getRowModel().rows.map((row) => (
            <li className={styles.memberItem} key={row.id}>
              <div className={styles.memberAvatar}>
                <AccountCircleIcon style={{ fontSize: 40, color: '#1D9FD6' }} /> {/* アイコンのスタイルをカスタマイズ可能 */}
              </div>
              <div className={styles.memberDetails}>
                {row.getVisibleCells().map((cell) => (
                  <span
                    key={cell.id}
                    className={
                      cell.column.id === 'role'
                        ? `${styles.memberRole} ${cell.getValue() === '管理者' ? styles.adminRole : styles.userRole}`
                        : styles.memberInfo
                    }
                  >
                    {cell.column.id === 'role' ? (
                      <span>{cell.getValue() as string | number}</span>
                    ) : (
                      cell.getValue() as string
                    )}
                  </span>
                ))}
              </div>
              <div className={styles.moreOptions}> {/* その他のオプション */}
                <button>・・・</button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <ul className={styles.ul}>
          {list.getRowModel().rows.map((row, rowIndex) => (
            <li className={styles.li} key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <div key={cell.id}>
                  <label style={{ width: '100%' }}>{cell.getValue() as string | number}</label>
                </div>
              ))}
            </li>
          ))}
        </ul>
      )}

      <Modal
        label={`${tabTitle}の編集`}
        isOpen={isModalOpen}
        onClose={closeModal}
        content={getModalContent()}
      />
    </div>
  );
};

export default ListContent;
