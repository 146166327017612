import React from 'react';
import ContentView from '../components/contentView';

const SystemProposal = () => {

  return (
    <div>
    <ContentView/>
    </div>
  );
};

export default SystemProposal;
