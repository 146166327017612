import React, {useState,useEffect, useRef} from 'react';
import styles from './index.module.scss';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store'; // Reduxの型
import { requirementItems, designItems, developmentItems, projectTop as top } from '../../config/menuItems';
import {ReactComponent as ArrowDropDownOpen } from '../../images/arrow_drop_down_open.svg'
import {ReactComponent as ArrowDropDownClose } from '../../images/arrow_drop_down_close.svg'

const Sidebar: React.FC = () =>{
  // アコーディオンメニューの開閉状態を管理
  const [isRequirementItemsOpen, setIsRequirementItemsOpen] = useState(false);
  const [isDesignItemsMenuOpen, setDesignItemsMenuOpen] = useState(false);
  const [isDevelopmentItemsMenuOpen,setIsDevelopmentItemsMenuOpen] = useState(false);

  // アコーディオンを開閉する関数
  const toggleRequirementItemsMenu = () => setIsRequirementItemsOpen(!isRequirementItemsOpen);
  const toggleDesignItemsMenu = () => setDesignItemsMenuOpen(!isDesignItemsMenuOpen);

  const toggleDevelopmentItemsMenu = () => setIsDevelopmentItemsMenuOpen(!isDevelopmentItemsMenuOpen);

  const contentRef = useRef<HTMLUListElement | null>(null);

  const selectedProjectId = useSelector((state: RootState) => state.auth.currentProject?.id || undefined); 
  const selectedProject = useSelector((state: RootState) => state.auth.currentProject);

  useEffect(()=>{
    return
  },[selectedProjectId])

  return (
    <div className={styles['sidebar']}>
      <div className={styles['menu']}>
      {selectedProjectId === undefined ? (
        <div className={styles.menu}>
        <ul className={styles['menu-wrapper']}>
          {/* 表示したいメニューアイテム */}
          <li className={`${styles['link']} ${styles['icon']} ${styles['icon-project-home']}`}>
            <NavLink to="/">プロジェクト</NavLink>
          </li>
          <li className={`${styles['link']} ${styles['icon']} ${styles['icon-organization']}`}>
            <NavLink to="/organization">組織</NavLink>
          </li>
        </ul>
      </div>

      ) :(

        <div className={styles['menu-wrapper']}>
          <div className={styles['project-name']}>
            <p>{selectedProject?.name}</p>
          </div>
        <ul
            className={`${styles['accordion-content']} ${isRequirementItemsOpen ? styles['open'] : ''}`}
            ref={contentRef}
            style={{
              maxHeight: '1000px'
            }}
          >
            {top.map((item, index) =>
            <li className={`${styles.link} ${styles.icon} ${styles[`icon-${item.icon}`]}`} key={index}>
              <NavLink
                to={`${item.link}`}
              >
                {item.name}
              </NavLink>
              </li>
            )}
          </ul>

          <div className={styles.sidebarItemTitle} onClick={toggleRequirementItemsMenu}>
            <span className={styles['menu-title']}>
              要件定義書
            </span>
            <p className={styles.sidebarArrowDropDown}>{isRequirementItemsOpen ? <ArrowDropDownClose/> : <ArrowDropDownOpen/>}</p>
          </div>
          <ul
            className={`${styles['accordion-content']} ${isRequirementItemsOpen ? styles['open'] : ''}`}
            ref={contentRef}
            style={{
              maxHeight: isRequirementItemsOpen ? '1000px' : '0px',
            }}
          >
            {requirementItems.map((item, index) =>
            <li className={`${styles.link} ${styles.icon} ${styles[`icon-${item.icon}`]}`} key={index}>
              <NavLink
                to={`${item.link}`}
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                {item.name}
              </NavLink>
            </li>
            )}
          </ul>

          <div className={styles.sidebarItemTitle} onClick={toggleDesignItemsMenu} >
            <span className={styles['menu-title']}>
              基本設計書
            </span>
            <p className={styles.sidebarArrowDropDown}>{isDesignItemsMenuOpen ? <ArrowDropDownClose/> : <ArrowDropDownOpen/>}</p>
          </div>
          <ul
            className={`${styles['accordion-content']} ${isDesignItemsMenuOpen ? styles['open'] : ''}`}
            ref={contentRef}
            style={{
              maxHeight: isDesignItemsMenuOpen ? '1000px' : '0px',
            }}
          >
            {designItems.map((item, index) =>
            (item.name === "アプリ設計書" || item.name === "フィールド設計書"  ) ? (
              <li className={`${styles.link} ${styles.icon} ${styles[`icon-${item.icon}`]}`} key={index}>
                <NavLink to={`${item.link}`} className={({ isActive }) => (isActive ? 'active' : '')}>
                  {item.name}
                </NavLink>
              </li>
            ) : 
            <li className={`${styles.disabled} ${styles.link} ${styles.icon} ${styles[`icon-${item.icon}`]}`} key={index}>
                  {item.name}
              </li>
          )}
          </ul>

          <div className={styles.sidebarItemTitle} onClick={toggleDevelopmentItemsMenu}>
            <span className={styles['menu-title']}>
              開発
            </span>
            <p className={styles.sidebarArrowDropDown}>{isDevelopmentItemsMenuOpen ? <ArrowDropDownClose/> : <ArrowDropDownOpen/>}</p>
          </div>
          <ul
            className={`${styles['accordion-content']} ${isDevelopmentItemsMenuOpen ? styles['open'] : ''}`}
            ref={contentRef}
            style={{
              maxHeight: isDevelopmentItemsMenuOpen ? '1000px' : '0px',
            }}
          >
            {developmentItems.map((item, index) =>
            <li className={`${styles.disabled} ${styles['link']} ${styles.icon} ${styles[`icon-${item.icon}`]}`} key={index}>
                {item.name}
            </li>
            )}
          </ul>
        </div>
      )}
      </div>
    </div>
  );
};

export default Sidebar;
