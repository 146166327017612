import React, { useState } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { ColumnDef } from '@tanstack/react-table';
import ProjectTopListContent from '../projectTopListContent';
import styles from './index.module.scss';

interface ProjectTopTabsProps {
  tabTitles: string[];
  dataSets: object[][];
  columns: Array<ColumnDef<object>[]>;
  updateData: (rowIndex: number, columnId: string, value: string | number, tabIndex: number) => void;
  onTabChange: (index: number) => void;
}


const ProjectTopTabs = ({ dataSets, columns, tabTitles, updateData, onTabChange }: ProjectTopTabsProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <Tabs className={styles['tab']} onSelect={(index) => {onTabChange(index);setSelectedIndex(index);}} selectedIndex={selectedIndex}>
      <TabList className={styles['tab-list']}>
        {tabTitles.map((title, index) => (
          <Tab
            key={index}
            className={styles['tab-list-name']}
            selectedClassName={styles['tab-list-name--selected']}
          >
            {title}
          </Tab>
        ))}
      </TabList>

      {dataSets.map((data, index) => (
        <TabPanel key={index} className={styles['custom-tab-panel']}>
          <ProjectTopListContent
            data={data}
            columns={columns[index]}
            updateData={(rowIndex, columnId, value) => updateData(rowIndex, columnId as string, value, index)}
            tabTitle={tabTitles[selectedIndex]}
          />
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default ProjectTopTabs;
