import React from 'react';
import styles from './index.module.scss';
import Button from '../button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { Document } from '../../interface/document';
import { dateToString } from '../../functions/datetime';

interface ModalProps {
    document: Document
}

const DocumentHeader: React.FC<ModalProps> = ({document}) => {
    const navigate = useNavigate();
    const handleHistoryClick = () => {
        navigate(`${window.location.pathname}/history`);
    };

    return (
        <div className={styles.documentHeader}>
            <h1>システム概要書</h1>
            <div className={styles.documentHeader__info}>
                <p>最終更新: {document.modified_at === null ? dateToString(document.created_at) : dateToString(document.modified_at)}</p>
                <AccountCircleIcon className={styles.documentHeader__info__svg} />
                <p>{document.modified_by.display_name}</p>
                <Button label='生成履歴' styleType='white' onClick={handleHistoryClick} />
            </div>
        </div>
    )
};

export default DocumentHeader;