import '@xyflow/react/dist/style.css';
import { useState,useEffect } from 'react';
import { ReactFlow, type Node, Background, BackgroundVariant } from '@xyflow/react';
import { initialNodes,nodeTypes } from './nodesConfig';
import { defaultEdgeOptions,edges } from './edgesConfig';

// 親から渡されるステータス配列を受け取るためのpropsを追加
interface Props {
    statusUpdates: { id: string; status: 'ready' | 'in_progress' | 'completed' }[];
    }

const OutputGenerationStatusDisplay: React.FC<Props> = ({ statusUpdates }) =>{
    const [nodes, setNodes] = useState<Node[]>(initialNodes);
    const proOptions = { hideAttribution: true };
    // 渡されたstatusUpdatesに基づいてノードのステータスを更新する 前提：idと生成物名は一緒
      useEffect(() => {
        const updatedNodes = nodes.map((node) => {
          const foundUpdate = statusUpdates.find((update) => update.id === node.id);
          if (foundUpdate) {
            return {
              ...node,
              data: { ...node.data, status: foundUpdate.status }, // ステータスを更新
            };
          }
          return node;
        });

        setNodes(updatedNodes);
      }, [statusUpdates]);

  return (
    <div style={{ height: 450 }}>
    <ReactFlow
      nodes={nodes}
      edges={edges}
      fitView
      nodeTypes={nodeTypes}
      zoomOnScroll={false} // ズームを無効化
      zoomOnPinch={false}  // ズームを無効化
      zoomOnDoubleClick={false} // ズームを無効化
      defaultEdgeOptions={defaultEdgeOptions}
      proOptions={proOptions}
    >
    <Background variant={BackgroundVariant.Dots} gap={10} size={1}/>
    </ReactFlow>
    </div>
  );
}

export default OutputGenerationStatusDisplay;
