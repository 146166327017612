import React from 'react';
import styles from './index.module.scss';
import close from '../../images/close.svg'

interface ModalProps {
  label: string;
  isOpen: boolean;
  onClose: () => void;
  content: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ label, isOpen, onClose, content }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <div className={styles.modalTitle}>
          <h2>{label}</h2>
          <div className={styles.closeButton} onClick={onClose}>
            <img src={close} alt="" />
          </div>
        </div>
        
        <div className={styles.content}>{content}</div>
      </div>
    </div>
  );
};

export default Modal;
