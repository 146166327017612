import React,{useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss'
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { DocumentApi } from '../../functions/api/document';
import { ReactComponent as ContextMenu } from "../../images/context_menu.svg"; 
import Button from '../button';
import {ReactComponent as ArrowDropDownClose } from '../../images/arrow_drop_down_close.svg'
import toast from 'react-hot-toast';

interface SummaryProp {
  id:string,
  createdAt:string,
  title:string,
  content:string,
  client:string,
  meetingDate: string
  modifiedAt: Date
}
interface SummaryProps extends Array<SummaryProp>{}

const SummaryListView = () => {
  const [notes, setNotes] = useState<SummaryProps>([]); // サンプルデータを直接セット
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null)
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState<string>(''); // ソートする列の状態
  const [isAscending, setIsAscending] = useState<boolean>(true); // 昇順・降順
  const [editMode, setEditMode] = useState<string | null>(null);
  const [editedNote, setEditedNote] = useState<Partial<SummaryProp>>({});
  const { user } = useSelector((state: RootState) => state.auth);
  const {currentProject} = useSelector((state: RootState) => state.auth)

  // APIリクエストで議事録を取得
  useEffect(() => {
    const fetchSummaryList = async () =>{
          if(user !== null && currentProject !== null){
            const documentApi = new DocumentApi();

            const response = await documentApi.getAll("summary",currentProject.id)
          if (response !== null) {
            console.log("Summary",response)
            // レスポンスデータをSummaryPropの型にマッピング
            const formattedData: SummaryProp[] = response.map((item: any) => ({
              id: item.id,
              createdAt: item.created_at,
              title: item.name,
              content: item.content,
              client: '株式会社ノベルワークス',
              meetingDate: item.created_at,
              modifiedAt: new Date(item.modifiedAt),
            }));
          console.log(formattedData)
          setNotes(formattedData); // ステートにセット
          setIsLoading(false); // ローディング終了
          }
        
        }else {
          setError(error);
          navigate("/project-top")
        }
    };

    fetchSummaryList(); // 非同期関数を呼び出す
  }, [user?.id,currentProject?.id]);
  
  const handleUpdate = async (id: string) => {
    if (!user) {
      toast.error("ユーザーが見つかりません");
      navigate("/signin");
      return null;
    }

    if (!editedNote.title) {
      toast.error("タイトルは必須です");
      return null;
    }

    const updatedNote = { id, title: editedNote.title };

    console.log("Saving updated note:", updatedNote);

    const documentApi = new DocumentApi();

    const response = await documentApi.updateInfo(id, updatedNote.title, user.id, currentProject.id, user?.organization_id)
    if (response !== null){
      toast.success('保存に成功')
      console.log("Successfully updated note:", response);
    
      setNotes((prevNotes) =>
        prevNotes.map((note) =>
          note.id === id ? { ...note, title: updatedNote.title } : note
        )
      );
      setEditMode(null);
      setEditedNote({});
    }
  };

  // 日付フォーマット変換 (表示用)
  const formatDate = (date: string): string => {
    const parsedDate = new Date(date);
    return parsedDate.toISOString().split("T")[0]; // YYYY-MM-DD
  };

  const handleSort = (column: keyof SummaryProp, type: "date" | "string") => {
    const sortedNotes = [...notes].sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];
  
      if (type === "date") {
        return isAscending
          ? new Date(aValue as string).getTime() - new Date(bValue as string).getTime()
          : new Date(bValue as string).getTime() - new Date(aValue as string).getTime();
      }
  
      if (type === "string") {
        return isAscending
          ? (aValue as string).localeCompare(bValue as string)
          : (bValue as string).localeCompare(aValue as string);
      }
  
      return 0;
    });
  
    setNotes(sortedNotes);
    setIsAscending(!isAscending); // ソートの順序を反転
    setSortColumn(column); // 現在のソート対象列を記録
  };

    const handleViewDetails = (note: SummaryProp) => {
      if (!editMode) {
        navigate(`/summary/${note.id}`, { state: { note } });
      }
    };
  

  // ローディング状態やエラーメッセージの表示
  if (isLoading) {
    return <div>ロード中...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const handleEdit = async (note: SummaryProp) => {
    if (!user) {
      toast.error("ユーザーが見つかりません");
      navigate("/signin");
      return null;
    }

    setEditMode(note.id);
    setEditedNote({ ...note });
    setDropdownOpen(null); 
  };

  const handleCancel = async (id: string) => {
    if (!user){
      toast.error('ユーザーが見つかりません');
      navigate('/project-top');
      return null;
    }
      setEditMode(null);
      setEditedNote({});
  };

  const handleInputChange = (field: keyof SummaryProp, value: string) => {
    setEditedNote((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div>
      {editMode && (
        <div className={styles.buttonGroup}>
          <Button
            label="保存"
            styleType="blue"
            onClick={() => handleUpdate(editMode)}
          />
          <Button
            label="キャンセル"
            styleType="white"
            onClick={() => handleCancel(editMode)}
          />
        </div>
      )}
      <table className={styles.meetingTable}>
      <thead>
  <tr>
    <th>
      作成日
      <button onClick={() => handleSort("createdAt", "date")}>
        <ArrowDropDownClose className={styles.arrowDropDownCloseSort} />
      </button>
    </th>
    <th>
      打ち合わせ日
      <button onClick={() => handleSort("meetingDate", "date")}>
        <ArrowDropDownClose className={styles.arrowDropDownCloseSort} />
      </button>
    </th>
    <th>
      名前
      <button onClick={() => handleSort("title", "string")}>
        <ArrowDropDownClose className={styles.arrowDropDownCloseSort} />
      </button>
    </th>
    <th className={styles["meetingTable-detail-button"]}></th>
  </tr>
</thead>
        <tbody>
        {notes.map((note) => (
            <tr key={note.id}>
              <td onClick={() => handleViewDetails(note)} style={{cursor:"pointer"}}>
                  {formatDate(note.createdAt)}
              </td>
              <td onClick={() => handleViewDetails(note)} style={{cursor:"pointer"}}>
                  {formatDate(note.meetingDate)}
              </td>
              <td onClick={() => handleViewDetails(note)} style={{cursor:"pointer"}}>
                {editMode === note.id ? (
                  <input
                    className={styles["editableTitle"]}
                    type="text"
                    value={editedNote.title || ""}
                    onChange={(e) => handleInputChange("title", e.target.value)}
                  />
                ) : (
                  note.title
                )}
              </td>
              <td>
                  <div 
                    className={styles.dropdownWrapper}
                    onClick={(e) => {
                      e.stopPropagation(); 
                      setDropdownOpen(dropdownOpen === note.id ? null : note.id);
                    }}
                  >
                    <ContextMenu style={{cursor:"pointer"}}/>
                    {dropdownOpen === note.id && (
                      <div className={styles.dropdownMenu}>
                      <ul>
                      <li onClick={() => handleEdit(note)}>編集</li>
                    </ul>
                      </div>
                    )}
                  </div>
              
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SummaryListView;