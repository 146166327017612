import React, { useState, useRef, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { NavLink,useNavigate } from 'react-router-dom';
import { clearAuth } from '../../slices/auth';
import { azureLoginUri} from '../../config/apiConfig';
import { ReactComponent as ArrowDropDown } from '../../images/arrow-drop-down.svg'
import { AuthApi } from '../../functions/api/auth';
import toast from 'react-hot-toast';

const Header: React.FC = () => {
    const [isDropdownOpen,setIsDropdownOpen] = useState<Boolean>(false)
    const dropdownRef = useRef<HTMLDivElement>(null); // ドロップダウンの外部クリックを監視するために使用
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate('/'); // ロゴクリックで /projectTop に遷移
    };

    // ドロップダウンの表示/非表示を切り替える関数
      const toggleDropdown = () => {
          setIsDropdownOpen((prevState) => !prevState);
      };

    // 外部クリックを監視し、ドロップダウンを閉じる
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
              setIsDropdownOpen(false); // 外部クリックがあった場合にドロップダウンを閉じる
          }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);

  const handleLogout = async () => {
    const authApi = new AuthApi();

    const response = await authApi.signout();

    if (response !== null) {

      dispatch(clearAuth())
      window.location.href = azureLoginUri; // ログアウト後にリダイレクト

    } else {
      toast.error('ログアウトに失敗しました')
    }
  };

    return (
        <div className={styles['header']}>
        {/* ロゴ表示 */}
          <div className={styles.header__icons}>
            <div onClick={handleLogoClick} className={`${styles['icon-logo']}`} >

            </div>
            <p>プロジェクト</p>
          </div>
            <nav>
              <div className={styles.header__icons}>
                  <i className={`${styles['icon-user']}`}></i>
                  <p onClick={toggleDropdown}>{user?.display_name}
                  <span className={styles.arrowDropDown}><ArrowDropDown/></span>
                  {/* ドロップダウン矢印 */}
                  </p>
                  {isDropdownOpen && (
                    <div className={styles['dropdownMenu']} ref={dropdownRef}>
                      <ul>
                        <li>
                          <NavLink to='/user-setting'>アカウント設定</NavLink>
                        </li>
                        <li>
                          <NavLink to="#" onClick={handleLogout}>
                            ログアウト
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  )}
              </div>
            </nav>
          </div>
    );
};

    export default Header;
