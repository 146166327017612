import toast from "react-hot-toast";
import { commonHeader, endpoints } from "../../config/apiConfig";
import { ErrorBoundary } from "../../errors/errorBoundary";
import {
    CreateProject,
    InviteExternalUserBody,
    InviteInternalUserBody,
    UpdateProject,
    UpsertVocabularyBody
} from "../../interface/project";

export class ProjectApi {
    private getRequestInfo(method: string, requestBody?: object): RequestInit {
        return {
            method: method,
            headers: commonHeader,
            credentials: 'include',
            keepalive: true,
            ...{
                body: JSON.stringify(requestBody)
            }
        };
    }

    private getRequestBody(baseBody: object, extraBody?: object): object {
        // ベースとなる共通データと、メソッドごとの追加データをマージ
        return { ...baseBody, ...extraBody };
    }

    async getAll(user_id: string, organization_id: string): Promise<Response | null> {
        const getAllProjects = endpoints.getAllProjects(user_id, organization_id);

        try {
            const requestInfo = this.getRequestInfo("GET");

            const requestBody = this.getRequestBody(requestInfo);

            const response = await fetch(getAllProjects, requestBody);

            if (response.ok) {
                console.log('Get all projects request is succeeded')
                return response
            }

            else {
                const errorMessage = ErrorBoundary(response.status)
                toast.error(errorMessage)
                return null;
            }
        } catch (error) {
            console.log(error)
            toast.error('予期しないエラー');
            return null;
        }
    }

    async getOne(id: string, organization_id: string): Promise<Response | null> {
        const getOneProject = endpoints.getProject(id, organization_id)

        try {
            const requestInfo = this.getRequestInfo("GET");

            const requestBody = this.getRequestBody(requestInfo);

            const response = await fetch(getOneProject, requestBody);

            if (response.ok) {
                console.log('Get selected project request is succeeded')
                return response
            }

            else {
                const errorMessage = ErrorBoundary(response.status)
                toast.error(errorMessage)
                return null;
            }
        } catch (error) {
            console.log(error)
            toast.error('予期しないエラー');
            return null;
        }
    }

    async delete(id: string, user_id: string, organization_id: string): Promise<Response | null> {
        const deleteProject = endpoints.deleteProject(user_id, id, organization_id);

        try {
            const requestInfo = this.getRequestInfo("DELETE");

            const requestBody = this.getRequestBody(requestInfo);

            const response = await fetch(deleteProject, requestBody);

            if (response.ok) {
                console.log('Delete selected project request is succeeded')
                toast.success("削除に成功")
                return response
            }

            else {
                const errorMessage = ErrorBoundary(response.status)
                toast.error(errorMessage)
                return null;
            }
        } catch (error) {
            console.log(error)
            toast.error('予期しないエラー');
            return null;
        }

    }

    async create(projectData: CreateProject): Promise<Response | null> {
        const createProject = endpoints.createProject;

        try {
            const requestInfo = this.getRequestInfo("POST", projectData);

            const requestBody = this.getRequestBody(requestInfo);

            const response = await fetch(createProject, requestBody);
            if (response.ok) {
                console.log('Create project request is succeeded')
                toast.success("作成に成功")
                return response
            }

            else {
                const errorMessage = ErrorBoundary(response.status)
                toast.error(errorMessage)
                return null;
            }
        } catch (error) {
            console.log(error)
            toast.error('予期しないエラー');
            return null;
        }

    }

    async update(updateData: UpdateProject): Promise<Response | null> {
        const updateProject = endpoints.updateProjectDetail;

        try {
            const requestInfo = this.getRequestInfo("POST", updateData);

            const requestBody = this.getRequestBody(requestInfo);

            const response = await fetch(updateProject, requestBody);
            if (response.ok) {
                console.log('Update selected project detail request is succeeded')
                toast.success("保存に成功");
                return response
            }

            else {
                const errorMessage = ErrorBoundary(response.status)
                toast.error(errorMessage)
                return null;
            }
        } catch (error) {
            console.log(error)
            toast.error('予期しないエラー');
            return null;
        }
    }

    async getInvitableUser(id: string, organization_id: string) {
        const getInvitableUser = endpoints.getInvitableUsers(id, organization_id);

        try {
            const requestInfo = this.getRequestInfo("GET");

            const requestBody = this.getRequestBody(requestInfo);

            const response = await fetch(getInvitableUser, requestBody);

            if (response.ok) {
                console.log('get invitable user request is succeeded')
                return response
            }

            else {
                const errorMessage = ErrorBoundary(response.status)
                toast.error(errorMessage)
                return null;
            }
        } catch (error) {
            console.log(error)
            toast.error('予期しないエラー');
            return null;
        }
    }

    async inviteInternalUser(inviteInternalUserData: InviteInternalUserBody): Promise<Response | null> {
        const inviteInternalUser = endpoints.inviteInternalUserToProject;

        try {
            const requestInfo = this.getRequestInfo("POST", inviteInternalUserData);

            const requestBody = this.getRequestBody(requestInfo);

            const response = await fetch(inviteInternalUser, requestBody);

            if (response.ok) {
                console.log('invite internal user request is succeeded')
                toast.success("招待に成功")
                return response
            }

            else {
                const errorMessage = ErrorBoundary(response.status)
                toast.error(errorMessage)
                return null;
            }
        } catch (error) {
            console.log(error)
            toast.error('予期しないエラー');
            return null;
        }
    }

    async inviteExternalUser(inviteExternalUserData: InviteExternalUserBody): Promise<Response | null> {
        const inviteExternalUser = endpoints.inviteExternalUserToProject;

        try {
            const requestInfo = this.getRequestInfo("POST", inviteExternalUserData);
            const requestBody = this.getRequestBody(requestInfo);

            const response = await fetch(inviteExternalUser, requestBody);

            if (response.ok) {
                console.log('invite external user request is succeeded')
                toast.success("招待に成功")
                return response
            }
            else {
                const errorMessage = ErrorBoundary(response.status)
                toast.error(errorMessage)
                return null;
            }
        } catch (error) {
            console.log(error)
            toast.error('予期しないエラー');
            return null;
        }
    }

    async upsertVocabulary(upsertVocabularyData: UpsertVocabularyBody): Promise<Response | null> {
        const upsertVocabularyUri = endpoints.upsertVocaburary;
        console.log("upsert", upsertVocabularyUri);

        try {
            const requestInfo = this.getRequestInfo("POST", upsertVocabularyData);
            const requestBody = this.getRequestBody(requestInfo);

            const response = await fetch(upsertVocabularyUri, requestBody);

            if (response.ok) {
                console.log("Upsert dictionary");
                return response;
            } else {
                const errorMessage = ErrorBoundary(response.status)
                toast.error(errorMessage)
                return null;
            }
        } catch (error) {
            console.log(error)
            toast.error('予期しないエラー');
            return null;
        };
    };
};