import React, { useEffect, useState } from 'react';
import { ChartDisplay } from '../components/chartDisplay';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { DocumentApi } from '../functions/api/document';
import toast from 'react-hot-toast';

const Er: React.FC = () => {
  const [diagramCode, setDiagramCode] = useState<object| null>(null);
  const { currentProject } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true);

  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true); 
  };

  useEffect(() => {
    if (currentProject?.requirements.contents.entity_relation?.id == null) {
      toast.error('ER図は生成されていません。');
      return navigate('/project-top');
    }

    const fetchERDiagram = async () => {
      setIsLoading(true)
      if (!currentProject.requirements.contents.entity_relation.id) {
        setIsLoading(false)
        toast.error("ER図が見つかりません");
        return;
      }
      const documentApi = new DocumentApi();

      const response = await documentApi.get(currentProject.requirements.contents.entity_relation.id, currentProject.id);

      if (response !== null) {
        console.log(response)
        const parsedData = JSON.parse(response.content)

        setDiagramCode(parsedData);
        setIsLoading(false)
      
      } else{
        setIsLoading(false)
        navigate('/project-top');
      }
    };
    fetchERDiagram();
  }, []);


  if(isLoading){
    return <div>ロード中...</div>;
  }


  return (
    <div>
    <h1>ER図</h1>
    {!hasError && diagramCode ? (
        <ChartDisplay id={currentProject.requirements.contents.entity_relation.id || ''} src={diagramCode} onError={handleError} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Er;
