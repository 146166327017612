import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import rehypeReact from 'rehype-react';
import remarkGfm from 'remark-gfm'
import { dateToString } from '../../functions/datetime';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setCurrentProjectRequirements } from '../../slices/auth';
import Button from '../button';
import ExportModal from '../exportModal';
import { DocumentExportData } from '../exportModal';
import { useNavigationPrompt } from '../../functions/navigationHooks';
import { DocumentApi } from '../../functions/api/document';
import { RequirementsApi } from '../../functions/api/requirements';
import toast from 'react-hot-toast';

interface SummaryProp {
  id: string,
  createdAt: string,
  title: string,
  content: string,
  client: string,
  meetingDate: string
}

// 議事録コンポーネント
const SummaryEdit: React.FC = () => {
  const [summaryContent, setSummaryContent] = useState('');
  const [initialSummaryContent, setInitialSummaryContent] = useState("");
  const { currentProject, user } = useSelector((state: RootState) => state.auth);
  const [summaryTitle, setSummaryTitle] = useState('')
  const [createdAtDate, setCreatedAtDate] = useState<string>('')
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  const [currentSummaryId, setCurrentSummaryId] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleEditMode = () => {
    if (isEditable) {
      handleUpdate(); // 保存処理を実行
    } else {
      handleLock();
    }
  };

  const handleUpdate = async () => {
    if (!currentSummaryId) {
      toast.error("議事録が見つかりません")
      return;
    }

    else if (!user) {
      toast.error("不明なユーザーです")
      return;
    }

    // ドキュメントのアップデートAPIリクエスト
    const documentApi = new DocumentApi();
    const response = await documentApi.update(currentSummaryId, user?.id, currentProject.id, user?.organization_id, summaryContent);

    if (response !== null) {
      console.log('summary is unlocked')
      setIsEditable(false);
      setInitialSummaryContent(summaryContent);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isEditable) {
        if (!currentSummaryId) {
          toast.error("議事録が見つかりません");
          return;
        }
  
        if (!user) {
          toast.error("不明なユーザーです");
          return;
        }
  
        const documentApi = new DocumentApi();
  
        // ロック解除処理
        documentApi.unlock(currentSummaryId, user?.id, currentProject.id, user?.organization_id);
  
        // カスタムメッセージ（古いブラウザでは表示される）
        event.preventDefault();
        event.returnValue = ''; // Chrome用: 空文字を指定
      }
    };
  
    // beforeunload イベントの登録
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      // クリーンアップ
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isEditable, user, currentProject]);

  useEffect(() => {
    setIsLoading(true);
    const note = location.state as {
      createdAt: string; note: SummaryProp
    } | undefined;
    console.log(note)
    if (note) {
      // データが存在する場合
      let summary = '';
      try {
        // 議事録がJSON形式かをチェック
        const parsedContent = JSON.parse(note.note.content);
        summary = parsedContent.detail
          ? parsedContent.detail
          : JSON.stringify(parsedContent, null, 2);
      } catch (error) {
        // JSON形式でない場合、そのままテキストとして扱う
        summary = note.note.content;
      }
      summary = summary
        .replace(/\\n/g, '\n') // エスケープ文字を正しい改行に変換
        .replace(/\\"/g, '"') // ダブルクオートを復元
        .replace(/^"|"$/g, '') // 外側のダブルクオートを削除
      console.log(summary)
      setSummaryContent(summary.replace(/\\n/g, '\n').replace(/\\"/g, ''));
      setInitialSummaryContent(summary.replace(/\\n/g, '\n').replace(/\\"/g, ''))
      setSummaryTitle(note.note.title);
      setCreatedAtDate(dateToString(note.note.createdAt));
      setCurrentSummaryId(note.note.id); // 状態にnoteを保存
      setError(null);
    } else {
      navigate('/signin')
    }
    setIsLoading(false);
  }, [location]);

  const handleLock = async () => {
    const documentApi = new DocumentApi()

    if (!currentSummaryId) {
      toast.error("議事録が見つかりません")
      return;
    }

    else if (!user) {
      toast.error("不明なユーザーです")
      return;
    }

    const response = await documentApi.lock(currentSummaryId, user?.id, currentProject.id, user?.organization_id);

    if (response !== null) {
      console.log('summary is locked')
      setIsEditable(true);
    }
  }

  const handleUnlock = async () => {

    const documentApi = new DocumentApi()

    if (!currentSummaryId) {
      toast.error("議事録が見つかりません")
      return;
    }

    else if (!user) {
      toast.error("不明なユーザーです")
      return;
    }

    const response = await documentApi.unlock(currentSummaryId, user?.id, currentProject.id, user?.organization_id);

    if (response !== null) {
      console.log('summary is unlocked')
    }
  }

  // 編集中の場合、ナビゲーションをブロック
  useNavigationPrompt(
    isEditable,
    '編集内容が保存されていません。ページを離れてもよろしいですか？',
    handleUpdate,
    handleUnlock
  );

  const handleExportClick = () => {
    if (summaryContent) {
      console.log(summaryContent)
      setShowExportModal(true);
    } else {
      console.error('parsedContent is empty');
    }
  };

  if (isLoading) {
    return <p>ロード中...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }
  const renderMarkdown = (content: string): React.ReactNode => {
    return unified()
      .use(remarkParse)
      .use(remarkRehype)
      .use(remarkGfm)
      .use(rehypeReact, { createElement: React.createElement, }) // Fragmentをrehype-reactに渡す})
      .processSync(content.replace(/\\n/g, '\n').replace(/\\"/g, '"')).result as React.ReactNode;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSummaryContent(e.target.value);
  };

  const handleBackClick = () => {
    window.history.back();
  };

  const handleCancel = async () => {

    if (!currentSummaryId) {
      toast.error("議事録が見つかりません");
      return;
    }

    if (!user?.id) {
      toast.error("ユーザーが見つかりません");
      return;
    }

    const documentApi = new DocumentApi();

    const response = await documentApi.unlock(currentSummaryId, user?.id, currentProject.id, user?.organization_id);

    if (response !== null) {
      console.log("unlock summary");
      setSummaryContent(initialSummaryContent)
      setIsEditable(false)
    }
  }

  const handleGenerateClick = async () => {
    setIsGenerating(true);
    if(!user) {
      toast.error("不明なユーザーです")
      return;
    }
    try {
      const requirementsApi = new RequirementsApi();
      const response = await requirementsApi.generateSystemProposalFromSummary(summaryContent, user.id, currentProject.id, user.organization_id);
      if(response !== null) {
        dispatch(setCurrentProjectRequirements(response));
        navigate("/system-proposal");
      } else {
        toast.error("システム概要書の生成に失敗しました");
      }
    } catch (error) {
      toast.error("不明なエラーが発生しました");
    }
    setIsGenerating(false);
  };

  return (
    <div className={styles.wrapper}>
      {showExportModal && (
        <ExportModal
          onClose={() => setShowExportModal(false)}
          exportData={{
            type: 'document',
            exportData: { content: summaryContent } as DocumentExportData, // ここでラップ
            name: 'summary'
          }}
        />

      )}
      <span className={styles["breadcrumb-link"]} onClick={handleBackClick}>
        議事録
      </span>
      <span className={styles["breadcrumb-separator"]}>&gt;</span>
      <span className={styles['breadcrumb-detail']}>詳細</span>

      <h1>
        <span className={styles["icon-back-arrow"]} onClick={handleBackClick}></span>
        {summaryTitle}
      </h1>
      <div className={styles['property']}>作成日: {createdAtDate}</div>
      <div className={styles['buttons']}>
        <Button label='エクスポート' onClick={handleExportClick} styleType='blue-generation-each-document' disabled={isEditable} />
        {isEditable && (
          <Button onClick={handleCancel} styleType='white' label={"キャンセル"} />
        )}
        <Button onClick={toggleEditMode} styleType="blue-generation-each-document" label={isEditable ? '保存' : '編集'} />
        <Button onClick={handleGenerateClick} styleType='blue' label={isGenerating ? "生成中" : "概要書を生成"} disabled={isGenerating || isEditable}/>
      </div>
      <div className={styles['summary']}>
        {isEditable ? (<textarea
          value={summaryContent}
          onChange={handleInputChange}
          readOnly={!isEditable}
          rows={20}
          style={{ width: '100%', fontSize: '14px', borderRadius: '4px' }}
        />) : (
          <div>{renderMarkdown(summaryContent)}</div>
        )}
      </div>
    </div>
  );

};

export default SummaryEdit;
