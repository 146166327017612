import React, { useEffect,useState, useRef } from 'react';
import { parseMermaidToExcalidraw } from "@excalidraw/mermaid-to-excalidraw";
import { Excalidraw,convertToExcalidrawElements,FONT_FAMILY,THEME,MainMenu, }  from "@excalidraw/excalidraw"
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AppState, BinaryFiles, ExcalidrawImperativeAPI } from '@excalidraw/excalidraw/types/types';
import styles from "./index.module.scss"
import Button from '../button';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { DocumentApi } from '../../functions/api/document';
import { useNavigationPrompt } from '../../functions/navigationHooks';
import { ExcalidrawElement } from '@excalidraw/excalidraw/types/element/types';

interface ChartProps {
    id: string
    src: { elements?: ExcalidrawElement[]; mermaid?: string };
    onError: () => void; 
}

export const ChartDisplay: React.FC<ChartProps> = ({ id, src, onError }) => {
  const [elements, setElements] = useState<readonly ExcalidrawElement[] | null>(null);
    const { user, currentProject} = useSelector((state: RootState) => state.auth); 
    const [files,setFiles] = useState<any | null | undefined>(null)
    const [isEditable, setIsEditable] = useState(false); // 編集モードの管理
    const [excalidrawAPI, setExcalidrawAPI] = useState<ExcalidrawImperativeAPI | null>(null);
    const navigate = useNavigate();
    const sceneData = useRef<{ elements: readonly ExcalidrawElement[]; appState: AppState; files: BinaryFiles } | null>(null);

    useEffect(() => {
      const fetchAndConvertElements = async () => {
          if (src?.mermaid){
          const formattedMermaid = src.mermaid.replace(/\\n/g,'\n').replace(/\\"/g,'"');
          const { elements, files } = await parseMermaidToExcalidraw(
            formattedMermaid,
          );

          if (elements && elements.length > 0) {
            const excalidrawElements = convertToExcalidrawElements(elements) as ExcalidrawElement[];
            if (excalidrawElements !== null) {
              const fontAdaptedElements: ExcalidrawElement[] = excalidrawElements.map(element => ({
                ...element,
                strokeColor: "#333333", // 全ノードの枠線を黒に
                fontSize: 16, // テキストのサイズ
                roughness:0,
                roundness:{
                  type:3,
                  value:3
                },
                fontFamily: FONT_FAMILY.Assistant,
                strokeWidth: 1
              }));
              console.log("Chart",fontAdaptedElements)
              setElements(fontAdaptedElements);
              setFiles(files)
          }
        }
        else {
          onError()
        }
        }
      };
      try{
      if (Array.isArray(src.elements) && src.elements.length > 0) {
      setElements(src?.elements);
      }
      else {
        fetchAndConvertElements();
      }
    }catch(error) {
      toast.error("生成された図形に不備があり、表示できません")
      onError(); // エラー発生時に親に通知
      navigate('/project-top')
      console.error("Error parsing Mermaid or converting elements:", error);
    }

    }, [src,onError]);

    useEffect(() => {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        if (isEditable) {
          if (!id) {
            toast.error("編集した図形が見つかりません");
            return;
          }
    
          if (!user) {
            toast.error("不明なユーザーです");
            return;
          }
    
          const documentApi = new DocumentApi();
    
          // ロック解除処理
          documentApi.unlock(id, user?.id, currentProject.id, user?.organization_id);
    
          // カスタムメッセージ（古いブラウザでは表示される）
          event.preventDefault();
          event.returnValue = ''; // Chrome用: 空文字を指定
        }
      };
    
      // beforeunload イベントの登録
      window.addEventListener('beforeunload', handleBeforeUnload);
    
      return () => {
        // クリーンアップ
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [isEditable, id, user, currentProject]);

    const handleSave = async() => {
      if (!user) {
        toast.error("ユーザーが見つかりません");
        return;
      }

      if (elements) {
          const documentApi = new DocumentApi();

          const updateData = {
            "elements":elements
          }

          const response = await documentApi.update(id, user?.id, currentProject.id, user.organization_id, updateData)
  
          if (response !== null) {
            setIsEditable(false); // 保存後に編集モードを終了
            return;
          }
      } else {
        toast.error("保存する図形がありません");
        return;
      }
    };

    const handleUnlock = async () =>{

      const documentApi = new DocumentApi()
  
      if (!id) {
        toast.error("編集した図形が見つかりません");
        return; 
      }
  
      else if (!user) {
        toast.error("不明なユーザーです");
        return;
      }
  
      const response = await documentApi.unlock(id, user?.id, currentProject.id, user?.organization_id);
  
      if (response !== null) {
        console.log('work flow is unlocked')
      } 
    }

    const handleLock = async() =>{
      const documentApi = new DocumentApi()
  
      if (!id) {
        toast.error("編集した図形が見つかりません")
        return;
      }
  
      else if (!user) {
        toast.error("不明なユーザーです")
        return;
      }

      if (!excalidrawAPI) {
        toast.error("もう一度やり直してください");
        return;
      }

      const response = await documentApi.lock(id, user?.id, currentProject.id, user?.organization_id);
  
      if (response !== null) {
          console.log('plugin proposal is locked')
          setIsEditable(true);
      }
    }  

    useNavigationPrompt(
      isEditable,
      '編集内容が保存されていません。ページを離れてもよろしいですか？',
      handleSave,
      handleUnlock
    );

  
    const handleCancel = async() => {

        if (!id) {
          toast.error("編集した図形が見つかりません");
          return null;
        }
    
        if (!user) {
          toast.error("ユーザーが見つかりません");
          return null;
        }
    
        const documentApi = new DocumentApi();
    
        const response = await documentApi.unlock(id, user?.id, currentProject.id, user?.organization_id);
    
        if (response !== null) {
          console.log("unlock work flow");
          setIsEditable(false)
        } 
    
    };

    return (
      <div>
    <div className={styles["chart-container"]}>
      {/* 編集ボタン */}
      {!isEditable && (
        <div className={styles["buttons"]}>
          <Button
            styleType="blue"
            label="編集"
            onClick={handleLock}
          />
        </div>
      )}

      {/* 保存 & キャンセルボタン */}
      {isEditable && (
        <div className={styles["buttons"]}>
            <Button
            styleType="white"
            label="キャンセル"
            onClick={handleCancel}
          />
          <Button
            styleType="blue"
            label="保存"
            onClick={handleSave}
          />
        </div>
      )}
      </div>
        <div style={{ height: "750px", fontFamily:"Helvetica", zIndex:-1}} >
            {elements ? (
                <Excalidraw
                key={isEditable ? "editable" : "readonly"} 
                    initialData={{
                        elements: elements,
                        appState: {theme: THEME.LIGHT, currentItemFontSize: 40,currentItemFontFamily:2, viewModeEnabled: !isEditable},
                        scrollToContent: true,
                        files: files,
                    }
                  }
                  autoFocus
                  excalidrawAPI={(api)=> setExcalidrawAPI(api)}
                  onChange={(updatedElements, appState, updatedFiles) => {
                    setElements(updatedElements);
                    sceneData.current = { elements: updatedElements, appState, files: updatedFiles };
                  }}
                  langCode='ja-JP'
                >
                    <MainMenu >
                        <MainMenu.DefaultItems.SaveAsImage />
                    </MainMenu>
                </Excalidraw>
            ) : (
                <p>Loading...</p>
            )}
        </div>
        </div>
    )
};
