import React from 'react';
import styles from './index.module.scss';
import { useReactTable, ColumnDef, getCoreRowModel, flexRender } from '@tanstack/react-table';

interface TableComponentProps<TData> {
  data: TData[];
  columns: ColumnDef<TData>[];
  updateData: (rowIndex: number, columnId: keyof TData, value: string) => void;
  isEditable: boolean;
  isNonFuncRequirement?: boolean;
}

const TableContent = <TData extends object>({ data, columns, updateData, isEditable }: TableComponentProps<TData>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const isUrl = (value: string) => {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  };


  return (
    <table className={styles['table']}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, rowIndex) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {cell.column.id === 'delete' ? (
                  flexRender(cell.column.columnDef.cell, cell.getContext())
                ) : !isEditable && isUrl(cell.getValue() as string) ? (
                  <div className={styles['link']} onClick={() => window.open(cell.getValue() as string, '_blank')} style={{ cursor: 'pointer', color: 'blue'}}>
                    {cell.getValue() as string}
                  </div>
                ) : (
                  <textarea
                    value={cell.getValue() as string | number}
                    onChange={(e) =>
                      updateData(rowIndex, cell.column.id as keyof TData, e.target.value)
                    }
                    readOnly={!isEditable}
                    style={{ width: '100%', resize: 'none' }}
                  />
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableContent;
