import React, { useEffect } from "react";
import { useBlocker,useLocation } from "react-router-dom";

export function useNavigationPrompt(
    when: boolean,
    message: string,
    handleSave: () => Promise<void>,
    handleUnlock: () => Promise<void>
) {
    const blocker = useBlocker(() => when);
    const currentLocation = useLocation();

    useEffect(() => {
        if (when && blocker.state === 'blocked') {
            const nextPath = blocker.location.pathname;

            // 現在のパスと次のパスが同じ場合、リセットして続行
            if (currentLocation.pathname === nextPath) {
                blocker.proceed();
                return;
            }

            const confirmExit = window.confirm(message);
            if (confirmExit) {
            handleUnlock().then(() => blocker.proceed()); // Unlockリクエストのみ
            } else {
            blocker.reset(); // キャンセルの場合は何もしない
            }
        }
    }, [when, blocker, handleSave, handleUnlock, message]);
};