import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Document } from '../../interface/document';
import { dateToString } from '../../functions/datetime';
import { DocumentApi } from '../../functions/api/document';
import toast from 'react-hot-toast';

const GenerationHistory = () => {
    const [documents, setDocuments] = useState<Document[]>([]);
    const { type } = useParams();
    const navigate = useNavigate();
    const { currentProject, user } = useSelector((state: RootState) => state.auth);
    const documentApi = new DocumentApi();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleGetAllDocuments = async (): Promise<Document[] | null> => {

      
        if (!currentProject || !user) {
            navigate("/signin")
            return null;
        }

        if (!currentProject.id) {
          toast.error("システム概要書が見つかりません")
          return null
        }

        if (!type) {
            toast.error("不明なドキュメントタイプ")
            return null;
        }

        const response = await documentApi.getAll("system-proposal", currentProject.id);
        if (response !== null) {
            return response
        } else {
            return null;
        }
    };

    useEffect(() => {
        const fetchAllDocuments = async () => {
            const response = await handleGetAllDocuments();
            console.log(response);
            if (response) {
                const allDocuments: Document[] = response;
                console.log("All Documents", allDocuments);
                setDocuments(allDocuments);
            } else {
                setDocuments([]);
            }
        }

        fetchAllDocuments();

    }, []);

    return (
        <div className={styles.history} style={{position: "relative"}}>
            <div className={styles.header}>
                <div className={styles.header__navi}>
                    <p>システム概要書</p>
                    <p>＞</p>
                    <p>生成履歴</p>
                </div>
                <div className={styles.header__title}>
                    <ArrowBackIcon className={styles.icon} onClick={handleGoBack} />
                    <h2>生成履歴</h2>
                </div>
            </div>
            <div className={styles.body}>
                <table className={styles.table}>
                    <thead className={styles.body__header}>
                        <tr>
                            <th>生成日時</th>
                            <th colSpan={2}>更新者</th>
                        </tr>
                    </thead>
                    <tbody>
                    {documents.map((document) => {
                      const isCurrentVersion = currentProject.requirements.contents.system_proposal.id === document.id;

                      return (
                        isCurrentVersion ? (
                          // 現在のバージョンの場合の <tr>
                          <tr key={document.id} className={styles['current-version']} onClick={() => navigate("/system-proposal")}>
                            <td>
                              <p>{dateToString(document.created_at)}</p>
                              <label>現在のバージョン</label>
                            </td>
                            <td>
                              <AccountCircleIcon style={{ fontSize: 40, color: '#1D9FD6' }} />
                              {document.modified_by.display_name}
                            </td>
                            <td>
                              ・・・
                            </td>
                          </tr>
                        ) : (
                          // それ以外のバージョンの場合の <tr>
                          <tr key={document.id} className={styles['past-version']} onClick={() => navigate(`${document.id}`)}>
                            <td>
                              <p>{dateToString(document.created_at)}</p>
                            </td>
                            <td>
                              <AccountCircleIcon style={{ fontSize: 40, color: '#1D9FD6' }} />
                              {document.modified_by.display_name}
                            </td>
                            <td>
                              ・・・
                            </td>
                          </tr>
                        )
                      );
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default GenerationHistory;
