import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TabsList from '../components/tabsList';
import { RootState } from '../store';
import { ColumnDef } from '@tanstack/react-table';
import { Organization, OrganizationMember } from '../interface/organization';
import { OrganizationApi } from '../functions/api/organization';import { Navigate, useNavigate } from 'react-router-dom';
;

// //データを更新する関数
// const updateData = (rowIndex: number, columnId: string, value: string | number, tabIndex?: number) => {
//     console.log(`Tab ${tabIndex ?? '不明'} の行 ${rowIndex} の列 ${columnId} を ${value} に更新`);
//   };

const OrganizationTop: React.FC = () => {
    const [name, setName] = useState<string>('');
    const [url, setUrl] = useState<string | null>('');
    const [members, setMembers] = useState<OrganizationMember[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.auth);
    const [dataSets, setDataSets] = useState<
    [Array<{ property: string; description: string }>, Array<{ displayName: string; lastName: string; firstName: string; role: string }>]
    >([[], []]);
    const handleRenameMemberRole = (role: string) => {
        switch (role) {
            case "admin":
                return "管理者"
            case "manager":
                return "プロジェクト管理者"
            case "member":
                return "ユーザー"
        };
    };

    const tabTitles = ['詳細情報', 'メンバー'];

    // 列の定義
    const columns: Array<ColumnDef<object>[]> = [
        [
            {
            header: '項目名',
            accessorKey: 'property',
            },
            {
                header: '値',
                accessorKey: 'description',
            }
        ],
        [
            {
                header: '表示名',
                accessorKey: 'displayName',
            },
            {
                header: '姓',
                accessorKey: 'lastName',
            },
            {
                header: '名',
                accessorKey: 'firstName',
            },
            {
                header: '権限',
                accessorKey: 'role',
            }
        ],
    ];


    const handleGetOrganization = async (): Promise<Organization | undefined> => {
        if (!user) {
            navigate("/signin");
            return undefined
        };

        const organizationApi = new OrganizationApi();

        const response = await organizationApi.get(user.organization_id);


        if (response !== null) {
            const data = await response.json();
            return data
        }
    };

    useEffect(() => {
        setIsLoading(true);
        const fetchOrganization = async () => {
            const response = await handleGetOrganization();
            if (response) {
                setName(response.name);
                setUrl(response.url);
                setMembers(response.members);
            }
            setIsLoading(false);
        };
        fetchOrganization();
    }, [user]);

    // name, url, members が更新されたときに dataSets を更新
    useEffect(() => {
        const detailData = [
            { property: '組織名', description: name },
            { property: 'URL', description: url || '' },
        ];
    
        const membersData = members.map((member) => ({
            displayName: member.user.display_name,
            lastName: member.user.last_name,
            firstName: member.user.first_name,
            role: handleRenameMemberRole(member.role) || '', // デフォルト値を設定
        }));
    
        setDataSets([detailData, membersData]);
    }, [name, url, members]);

    return (
        <div>
        
            <h1>組織</h1>
            <TabsList
                tabTitles={tabTitles}
                dataSets={dataSets}
                columns={columns}
                //updateData={updateData}
            />
        </div>
    );
};

export default OrganizationTop;
