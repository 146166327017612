import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import close from '../../images/close.svg';
import Button from '../button';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { InviteInternalUserBody, InviteExternalUserBody, InviteInternalUserInfo, InviteExternalUserInfo } from '../../interface/project';
import { User } from '../../interface/user';
import InviteInternalUser from '../inviteInternalUser';
import InviteExternalUser from '../inviteExternalUser';
import { ProjectApi } from '../../functions/api/project';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

interface MemberItem {
    user: User;
    role: string | 'owner' | 'contributor' | 'member';
    isChecked: boolean;
}

interface ModalProps {
    isAdmin: boolean;
    isOpen: boolean;
    onClose: () => void;
}

const InviteProjectModal: React.FC<ModalProps> = ({ isAdmin, isOpen, onClose }) => {
    const [invitableMembers, setInvitableMembers] = useState<MemberItem[]>([]);
    const [isLock, setIsLock] = useState(false);
    const navigate = useNavigate();
    const [externalUsers, setExternalUsers] = useState<InviteExternalUserInfo[]>([])
    const user = useSelector((state: RootState) => state.auth.user);
    const currentProject = useSelector((state: RootState) => state.auth.currentProject);

    const getCheckedUsers = (data: MemberItem[]) => {
        return data.filter(item => item.isChecked && item.role != "").map((item): InviteInternalUserInfo => (
            {id: item.user.id, role: item.role}
        ));
    };

    const handleGetInvitableUsers = async () => {
        if(!user) {
            toast.error('ユーザーが見つかりません');
            return;
        };

        if(!currentProject.id) {
            toast.error('プロジェクトが選択されていません');
            return;
        }

        const projectApi = new ProjectApi();

        const response = await projectApi.getInvitableUser(currentProject.id, user.organization_id);

        if (response !== null) {
                const data = await response.json();
                return data
            } else {
                console.log('Failed get invitable users', response);
                toast.error('招待可能なユーザーの取得に失敗しました');
            }
    }

    const handleSubmit = async () => {
        setIsLock(true);
        if(!user) {
            toast.error('ユーザーが見つかりません');
            return;
        };

        if(!currentProject.id) {
            toast.error('プロジェクトが選択されていません');
            return;
        }

        // Internal User
        if (invitableMembers) {
            const selectedUsers = getCheckedUsers(invitableMembers);
            console.log("internalUsers", selectedUsers);
            
            const inviteInternalUserData: InviteInternalUserBody = {
                invite_users: selectedUsers,
                project_id: currentProject.id,
                user_id: user.id,
                organization_id: user.organization_id
            }
            console.log("reqBody", inviteInternalUserData)

            const projectApi = new ProjectApi();

            const response = await projectApi.inviteInternalUser(inviteInternalUserData);
        
            if (response !== null) {
                const data = await response.json();
                setTimeout(() => navigate(0), 300); // 状態が反映されるのを待つ
                console.log("response data: ", data);
                
                }
                else {
                    
                console.error('メンバーの追加に失敗しました: ');
                }
        };

        // External User
        if (externalUsers.length !== 0) {
            console.log('externalMembers', externalUsers);

            console.log("members: ", externalUsers);
            const inviteExternalUserData: InviteExternalUserBody = {
                invite_users: externalUsers,
                project_id: currentProject.id,
                user_id: user.id,
                organization_id: user.organization_id
            }
            console.log(inviteExternalUserData);

            const projectApi = new ProjectApi();

            const response = await projectApi.inviteExternalUser(inviteExternalUserData);

            if (response !== null) {
                    const data = await response.json();
                    setTimeout(() => navigate(0), 300); // 状態が反映されるのを待つ
                    console.log("response data: ", data);
                }
                else {
                    console.error('メンバーの追加に失敗しました: ');
                }
        }

        onClose();
        setIsLock(false);
    };

    useEffect(() => {
        console.log('Fetch invitable users');
        const fetchInvitableUsers = async () => {
            const response = await handleGetInvitableUsers();
            console.log(response);
            if(response) {
                const invitableUsers: User[] = response;
                const memberItems = invitableUsers.map((user): MemberItem => (
                        {
                            user: user,
                            role: '',
                            isChecked: false,
                        }
                ));
                console.log('memberItems', memberItems);
                setInvitableMembers(memberItems);
            } else {
                setInvitableMembers([]);
            }
        }

        fetchInvitableUsers();

    }, []);

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <div className={styles.modalHeader}>
                    <h2>メンバー招待</h2>
                    <button className={styles.closeButton} onClick={onClose}>
                    <img src={close} alt="閉じる" />
                    </button>
                </div>
                <div className={styles.modalContent}>
                    <p className={styles.description}>
                        組織に参加しているメンバーを招待できます。選択時には必ず権限を選択してください。
                    </p>

                    <div className={styles.infoSection}>
                        <button className={styles.infoButton}>?</button>
                        <span className={styles.infoText}>権限について</span>
                    </div>
                    <div className={styles.inviteInternalMembers}>
                        <span className={styles.itemName}>組織内のメンバーを追加</span>
                        <InviteInternalUser members={invitableMembers} setMembers={setInvitableMembers} />
                    </div>
                    {isAdmin &&
                    <div className={styles.inviteExternalUser}>
                        <span className={styles.itemName}>組織外のユーザーを追加</span>
                        <p className={styles.description}>組織に参加していないユーザーはメールアドレスで招待できます。招待されたユーザーは組織にも追加されます。</p>
                        <InviteExternalUser members={externalUsers} setMembers={setExternalUsers}/>
                    </div>
                    }
                </div>
                <div className={styles.modalFooter}>
                    <Button label="キャンセル" styleType="white" onClick={onClose} />
                    <Button label={!isLock ? "招待" : "招待中"} styleType="blue" onClick={handleSubmit} disabled={isLock} />
                </div>
            </div>
        </div>
    )
};

export default InviteProjectModal;
