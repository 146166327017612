import React from 'react';
import './App.scss';
import 'ress';
import Header from './components/header';
import Sidebar from './components/sidebar';
import Home from './pages/home';
import OrganizationTop from './pages/organizationTop';
import ProjectTop from './pages/projectTop';
import Vocabulary from './pages/vocabulary/vocabulary';
import SystemProposal from './pages/systemProposal';
import Summary from './pages/summary';
import FuncRequirement from './pages/funcRequirement';
import NonFuncRequirement from './pages/nonFuncRequirement';
import BusinessFlow from './pages/workFlow';
import SystemConfigChart from './pages/systemConfigChart';
import AppDesign from './pages/appDesign';
import Er from './pages/er';
import FieldDesign from './pages/fieldDesign';
import Plugin from './pages/plugin';
import UserSettings from './components/userSettingDisplay';
import SummaryEdit from './components/summaryEdit';
import GenerationHistory from './components/generationHistory';
import GenerationHistoryDetail from './components/generationHistoryDetail';
import Signin from './components/signin';

import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';

// Layoutコンポーネント
const Layout = () => (
  <div className="app">
    <Header />
    <Sidebar />
    <div className="inner">
      <Outlet /> {/* ページコンテンツがここにレンダリングされます */}
    </div>
  </div>
);

// ルートの定義
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "organization", element: <OrganizationTop /> },
      { path: "project-top", element: <ProjectTop /> },
      { path: "vocabulary", element: <Vocabulary />},
      { path: "system-proposal", element: <SystemProposal /> },
      { path: "signin", element:<Signin />},
      { path: "summary", element: <Summary /> },
      { path: "functional-requirement", element: <FuncRequirement /> },
      { path: "non-functional-requirement", element: <NonFuncRequirement /> },
      { path: "work-flow", element: <BusinessFlow /> },
      { path: "system-architecture", element: <SystemConfigChart /> },
      { path: "app-design", element: <AppDesign /> },
      { path: "entity-relation", element: <Er /> },
      { path: "field-design", element: <FieldDesign /> },
      { path: "plugin-proposal", element: <Plugin /> },
      { path: "user-setting", element: <UserSettings /> },
      { path: "summary/:id", element: <SummaryEdit /> },
      { path: "*", element: <Home /> },
      { path: ":type/history", element: <GenerationHistory /> },
      { path: ":type/history/:selectedId", element: <GenerationHistoryDetail /> },
    ],
  },
]);

// Appコンポーネント
function App() {
  return <RouterProvider router={router} />;
}

export default App;