import { commonHeader, endpoints } from "../../config/apiConfig";
import { ErrorBoundary } from "../../errors/errorBoundary";
import { azureLoginUri } from "../../config/apiConfig";
import toast from "react-hot-toast";


export class AuthApi {
    private getRequestInfo (method: string, requestBody?: object): RequestInit {
        return {
            method: method,
            headers: commonHeader,
            credentials: 'include',
            keepalive: true,
            mode: 'no-cors',
            ...{
                body: JSON.stringify(requestBody)
            }
        };
    }

    private getRequestBody(baseBody: object, extraBody?: object): object {
        // ベースとなる共通データと、メソッドごとの追加データをマージ
        return { ...baseBody, ...extraBody };
    }

    async signin() {


    }

    async signout(){
        const signout = endpoints.logoutApp;
        const logout = `/.auth/logout?post_logout_redirect_uri=${encodeURIComponent(azureLoginUri)}`;

        try{
        const requestInfo = this.getRequestInfo("GET");
        const requestBody = this.getRequestBody(requestInfo);

        const response = await fetch(signout, requestBody);

        if (response.status === 302) {
            console.log('signout request is succeeded')
            await fetch(logout, { method: 'GET' }); // リクエスト
            return response
        }

        else {
            const errorMessage = ErrorBoundary(response.status)
            toast.error(errorMessage) 
            return null;  
        }
        } catch (error) {
            console.log(error)
            toast.error("予期しないエラー")
            return null;
        }
    }

}