export const ERROR_MESSAGES: Record<number, string> = {
  400: "不正なリクエストです",
  401: "認証に失敗しました",
  403: "権限がありません",
  404: "意図しないエラーが発生しました",
  408: "通信が不安定です。時間をおいて再施行してください",
  409: "すでに招待済みのユーザーが含まれています",
  422: "リクエストの型が異なります",
  423: 'ほかのユーザーが編集中です',
  500: "サーバ内部でエラーが発生しました。時間をおいて再試行してください",
  503: "サーバが不安定です。時間をおいて再試行してください",
  504: "サーバ側でタイムアウトしました。時間をおいて再試行してください"
}