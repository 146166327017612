import React, { useState } from "react";
import styles from "./index.module.scss";
import { useDropzone } from "react-dropzone"; 
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import PizZip from "pizzip";
import { DOMParser } from "@xmldom/xmldom";
import { setCurrentProjectRequirements } from "../../slices/auth";
import { RequirementsApi } from "../../functions/api/requirements";
import { Requirements } from "../../interface/requirements";
import toast from "react-hot-toast";

interface ImportModalProps {
  onClose: () => void;
  importType: "summary" | "transcribe";
}

const ImportModal: React.FC<ImportModalProps> = ({ onClose, importType }) => {
  const [textInput, setTextInput] = useState<string>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, currentProject } = useSelector(
    (state: RootState) => state.auth
  );
  const [isLoading, setIsLoading] = useState(false);

  const processFile = async(file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileExtension = file.name.split(".").pop();
      console.log(fileExtension)

    if (!["txt", "md", "docx"].includes(fileExtension || "")) {
      toast.error("許可されたファイル形式はテキスト、マークダウン、Word(docx)のみです");
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        if (fileExtension === "txt" || fileExtension === "md") {
          resolve(event.target?.result as string);
        } else if (fileExtension === "docx") {
          const content = event.target?.result as ArrayBuffer;
          const paragraphs = getParagraphs(content);
          resolve(paragraphs.join("\n"));
        }
        else {
        reject("未知のファイル形式です");
        }
      } catch (error) {
        reject(`処理エラー: ${file.name}`);
      }
    };

    reader.onerror = () => {
      reject(`ファイル読み込みエラー: ${file.name}`);
    };

    if (fileExtension === "docx") {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsText(file);
    }
  });
};

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        try {
          console.log('file selected')
          const files = Array.from(acceptedFiles);
          const results = await Promise.allSettled(files.map((file) => processFile(file)));
  
          const fileContents = results
          .filter((result) => result.status === 'fulfilled')
          .map((result) => (result as PromiseFulfilledResult<string>).value);
  
        if (fileContents.length > 0) {
          setTextInput(fileContents.join("\n\n"));
        } else {
          toast.error("すべてのファイルの処理に失敗しました");
        }
      } catch (error) {
        toast.error("予期しないエラーが発生しました");
      } finally {
        console.log("finally block executed");
      }
    } else {
      toast.error("ファイルが選択されませんでした");
    }
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((file) => {
        toast.error(`許可されていないファイル形式です: ${file.file.name}`);
      });
    },
    accept: {
      "text/plain": [".txt"],
      "text/markdown": [".md"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    },
  });

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        console.log("file selected");
  
        const files = Array.from(e.target.files);
        const results = await Promise.allSettled(
          files.map((file) => processFile(file))
        );
  
        const fileContents = results
          .filter((result) => result.status === "fulfilled")
          .map((result) => (result as PromiseFulfilledResult<string>).value);
  
        if (fileContents.length > 0) {
          setTextInput(fileContents.join("\n\n"));
        } else {
          toast.error("すべてのファイルの処理に失敗しました");
        }
      } else {
        toast.error("ファイルが選択されませんでした");
      }
    } catch (error) {
      toast.error("予期しないエラーが発生しました");
    } finally {
      console.log("finally block executed");
      if (e.target) {
        e.target.value = ""; // 初回ファイル選択のリセット
      }
    }
  };

  function str2xml(str: any) {
    if (str.charCodeAt(0) === 65279) {
      // BOM sequence
      str = str.substr(1);
    }
    return new DOMParser().parseFromString(str, "text/xml");
  }

  function getParagraphs(content: ArrayBuffer): string[] {
    const zip = new PizZip(content);
    const xml = str2xml(zip.files["word/document.xml"].asText());
    const paragraphsXml = xml.getElementsByTagName("w:p");
    const paragraphs: string[] = []; // すべての段落を格納

    for (let i = 0; i < paragraphsXml.length; i++) {
      let fullText = "";
      const textsXml = paragraphsXml[i].getElementsByTagName("w:t");
      for (let j = 0; j < textsXml.length; j++) {
        const textXml = textsXml[j];
        if (textXml.childNodes && textXml.childNodes[0]) {
          fullText += textXml.childNodes[0].nodeValue;
        }
      }
      if (fullText) {
        paragraphs.push(fullText);
      }
    }
    return paragraphs;
  }

  const handleGenerateSystemProposal = async () => {

      setIsLoading(true); 

        if(!user) {
          toast.error("Not found user");
          return;
        }

        const requirementsApi = new RequirementsApi()
        let response = null; 

        // インポートタイプに応じてリクエスト先を切り替える
        if (importType === "summary"){
          response = await requirementsApi.generateSystemProposalFromSummary(textInput, user.id, currentProject.id, user.organization_id);

        }
        else if (importType === "transcribe") {
          response = await requirementsApi.generateSystemProposalFromTranscription(textInput, user.id, currentProject.id, user.organization_id)
        }

        if (response !== null) {
          const requirements: Requirements = response;
          dispatch(setCurrentProjectRequirements(requirements));
          onClose(); // モーダルを閉じる
          navigate("/system-proposal"); // 成功したら/projectTopへ遷移
        }
        setIsLoading(false); // ローディング終了
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <button
            className={`${styles["icon-close-button"]}`}
            onClick={onClose}
            disabled={isLoading}
          ></button>
          <h2>
            {importType === "summary"
              ? "議事録のインポート"
              : "文字起こしのインポート"}
          </h2>
          <div className={styles["file-select-label"]}>
            <div style={{ fontSize: "16px" }}>
              ファイルを選択(.txt, .md, .docx)
            </div>
            <div style={{ fontSize: "14px" }}>
              {importType === "summary"
                ? "議事録を書き起こしたテキストファイルをアップロードできます。"
                : "文字起こしファイルをアップロードできます。"}
            </div>
          </div>

          <div {...getRootProps({
            className: `${styles["file-upload-background"]} ${isDragActive ? styles["drag-active"] : ""}`,
            })}>
            <input {...getInputProps()} />
            <div className={styles.fileUpload}>
              {isDragActive ? (
                <p>ここにドロップしてください...</p>
              ) : (
                <>
                  <span>ファイルを選択</span>
                  <span>ここにドラッグアンドドロップしてください...</span>
                  <span className={styles.fileLabel}>
                    ファイルを選択
                    <input type="file" onChange={handleFileChange}  disabled={isLoading} />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>

        <div className={styles["icon-or-text"]}></div>

        <div className={styles.textInputSection}>
          <div style={{ fontSize: "16px" }}>テキストを入力</div>
          <div style={{ fontSize: "14px" }}>
            {importType === "summary"
              ? "議事録のテキストをそのまま入力できます。"
              : "文字起こしのテキストをそのまま入力できます。"}
          </div>

          <textarea
            placeholder={
              importType === "summary"
                ? "議事録の内容を入力"
                : "文字起こしの内容を入力"
            }
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className={styles.actions}>
          <button
            className={styles.cancelButton}
            onClick={onClose}
            disabled={isLoading}
          >
            キャンセル
          </button>
          <button
            className={styles.importButton}
            onClick={handleGenerateSystemProposal}
            disabled={!textInput || isLoading} // ローディング中、もしくはファイルもテキストもないときに無効化
          >
            {isLoading ? "生成中..." : "システム概要書を生成"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImportModal;
