import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import InviteProjectModal from '../inviteProjectModal';
import { useReactTable, ColumnDef, getCoreRowModel } from '@tanstack/react-table';
import Modal from '../modal';
import Button from '../button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { Project, UpdateProject } from '../../interface/project';
import { useSelector,useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setCurrentProject } from '../../slices/auth';
import { ProjectApi } from '../../functions/api/project';
import toast from 'react-hot-toast';

interface ListComponentProps<LData> {
  data: LData[];
  columns: ColumnDef<LData>[];
  updateData: (rowIndex: number, columnId: keyof LData, value: string) => void;
  tabTitle: string;
}

const ProjectTopListContent = <TData extends object>({ data, columns, updateData, tabTitle }: ListComponentProps<TData>) => {
  const list = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { user, currentProject } = useSelector((state: RootState) => state.auth);

  const [projectData, setProjectData] = useState<UpdateProject>({
    name: currentProject?.name || null,
    description: currentProject?.description || null,
    start_date: currentProject?.start_date ? new Date(currentProject.start_date) : null,
    end_date: currentProject?.end_date ? new Date(currentProject.end_date) : null,
    client_name: currentProject?.client?.name || null,
    client_url: currentProject?.client?.url || null,
    project_id: currentProject.id || '',
    organization_id: user?.organization_id || ''
  });

  const startDateString = projectData.start_date instanceof Date ? projectData.start_date.toISOString().split('T')[0] : '';
  const endDateString = projectData.end_date instanceof Date ? projectData.end_date.toISOString().split('T')[0] : '';
  const [isFormValid, setIsFormValid] = useState(false);


  const navigate = useNavigate();

  const dispatch = useDispatch();

  // モーダルの開閉を管理するステート
  const [isModalOpen, setModalOpen] = useState(false);

  const [isLock, setIsLock] = useState(false);

  // 編集ボタンのクリックでモーダルを開く
  const openModal = () => setModalOpen(true);

  // モーダルを閉じる関数
  const closeModal = () => setModalOpen(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    // Check if the field is a date field and convert accordingly
    if (name === 'start_date' || name === 'end_date') {
        setProjectData((prevData) => ({
            ...prevData,
            [name]: value ? new Date(value) : null, // Convert string to Date
        }));
    } else {
        setProjectData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
};

  // フォームの入力内容を監視する useEffect フック
  useEffect(() => {
    // 必須項目がすべて入力されているかをチェックし、boolean 型に変換
    const isFormFilled = Boolean(
      projectData.name &&
      projectData.start_date &&
      projectData.end_date
    );
    setIsFormValid(isFormFilled);
  }, [projectData]);
  

   // プロジェクトデータの更新処理
  const handleUpdateProject = async () => {
    setIsLock(true);
    if (currentProject.id === null || user?.organization_id === undefined) {
      toast.error("不正な入力形式です")
      return;
      
    }
      const preparedProjectData:UpdateProject = {
        ...projectData,
        project_id: currentProject.id,
        organization_id: user?.organization_id
    };
    console.log('preparedProjectData:', preparedProjectData);

    const projectApi = new ProjectApi();

    const response = await projectApi.update(preparedProjectData);

    if (response !== null) {
        const data:Project = await response.json();
        dispatch(setCurrentProject(data));

        navigate('/project-top')

        setTimeout(() => navigate(0), 300); // 状態が反映されるのを待つ
        
        closeModal(); // モーダルを閉じる

      }
      setIsLock(false);
  };

  // タブに応じたモーダルコンテンツ
  const getModalContent = () => {
    switch (tabTitle) {
      case '詳細情報':
        return (
            <div>
              <form className={styles.content}>
                <div>
                  <label>
                    プロジェクト名<span className={styles.required}>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className={styles.inputField}
                    onChange={handleInputChange}
                    value={projectData.name ?? ''} // null の場合に空文字列を表示
                  />
                </div>
                <div>
                  <label>
                    説明
                  </label>
                  <textarea
                    placeholder="詳細を入力してください。空欄可。"
                    name="description"
                    onChange={handleInputChange}
                    className={styles.inputField}
                    value={projectData.description ?? ''} // null の場合に空文字列を表示
                  />
                </div>
                <div>
          <label>
            契約開始日<span className={styles.required}>*</span>
          </label>
          <input
            type="date"
            name="start_date"
            onChange={handleInputChange}
            className={styles.inputField}
            value={startDateString || ''} // null や undefined の場合に空文字列を表示
          />
          </div>
          <div>
            <label>
              契約終了日<span className={styles.required}>*</span>
            </label>
            <input
              type="date"
              name="end_date"
              onChange={handleInputChange}
              className={styles.inputField}
              value={endDateString || ''} // null や undefined の場合に空文字列を表示
            />
          </div>
          <div>
            <label>
              クライアント名
            </label>
            <input
              type="text"
              name="client_name"
              onChange={handleInputChange}
              className={styles.inputField}
              value={projectData.client_name ?? ''} // null の場合に空文字列を表示

            />
          </div>
          <div>
            <label>
              クライアントURL
            </label>
            <input
              type="text"
              name="client_url"
              onChange={handleInputChange}
              className={styles.inputField}
              value={projectData.client_url ?? ''} // null の場合に空文字列を表示
            />
          </div>
              </form>
              <div className={styles.buttonContainer}>
                <Button label="キャンセル" styleType="white" onClick={closeModal} />
                <Button label={!isLock ? "保存" : "保存中"} styleType="blue" onClick={handleUpdateProject} disabled={!isFormValid || isLock} />
              </div>
            </div>

        );
      case 'メンバー':
        return <InviteProjectModal isAdmin={false} isOpen={isModalOpen} onClose={() => setModalOpen(false)}/>;
      default:
        return <div>デフォルトの編集コンテンツ</div>;
    }
  };

  // 特定のタブ（メンバー）かどうかを判定
  const isMemberTab = tabTitle === 'メンバー';

  return (
    <div className={styles.ProjectTopListContent}>

        {tabTitle !== 'トップ' && (
          <div className={styles.title}>
            <h3>{tabTitle}</h3>
            {tabTitle === '詳細情報' ? (
              <Button label="編集" styleType="blue" onClick={openModal} />
            ) : tabTitle === 'メンバー' ? (
              <Button label="メンバー追加" styleType="blue" onClick={openModal} />
            ):null}
          </div>
        )}

      {isMemberTab ? (
        <ul className={styles.memberList}>
          {list.getRowModel().rows.map((row) => (
            <li className={styles.memberItem} key={row.id}>
              <div className={styles.memberAvatar}>
                <AccountCircleIcon style={{ fontSize: 40, color: '#1D9FD6' }} /> {/* アイコンのスタイルをカスタマイズ可能 */}
              </div>
              <div className={styles.memberDetails}>
                {row.getVisibleCells().map((cell) => (
                  <span
                  key={cell.id}
                  className={
                    cell.column.id === 'role'
                      ? `${styles.memberRole} ${cell.getValue() === '管理者' ? styles.adminRole : styles.userRole}`
                      : styles.memberInfo
                  }
                >
                  {cell.column.id === 'role' ? (
                    <span>{cell.getValue() as string | number}</span>
                  ) : (
                    cell.getValue() as string
                  )}
                </span>
                ))}
              </div>
              <div className={styles.moreOptions}> {/* その他のオプション */}
                <button>・・・</button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <ul className={styles.ul}>
          {list.getRowModel().rows.map((row, rowIndex) => (
            <li className={styles.li} key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <div key={cell.id}>
                  {tabTitle === '詳細情報' ? (
                  <span>{cell.getValue() as string}</span>
                  ) : (
                  <input
                    value={cell.getValue() as string | number}
                    onChange={(e) =>
                      updateData(rowIndex, cell.column.id as keyof TData, e.target.value)
                    }
                    style={{ width: '100%' }}
                  />
                  )}
                </div>
              ))}
            </li>
          ))}
        </ul>
      )}

      <Modal
        label={`${tabTitle}の編集`}
        isOpen={isModalOpen}
        onClose={closeModal}
        content={getModalContent()}
      />
    </div>
  );
};

export default ProjectTopListContent;
