
import { type Node, Handle, Position } from '@xyflow/react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

  const DocumentNode = (data: any) => {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(`/${data.id.replace(/_/g, '-')}`); // ボタンがクリックされたときに /{id} にナビゲート
    };

    return (
        <div className={`${styles['icon-box']}`}>
        <div className={`${styles['custom-node']}`}>
        <div className={`${styles['icon']} ${styles['icon-document']}`} style={{ display: 'flex', alignItems: 'center', gap: '10px',width:'100%' }}>
        <button className={`${styles['menu-title']}`}onClick={handleClick} disabled={data.data.status === 'ready'}>{data.data.label}</button>
        <div className={`${styles[`icon-${data.data.status}`]}`}></div>
        {/* Handles for connecting edges */}
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
        </div>
        </div>
        </div>
        );
    };
    const DiagramNode = (data: any) => {
        const navigate = useNavigate()
        const handleClick = () => {
            navigate(`/${data.id.replace(/_/g, '-')}`); // ボタンがクリックされたときに /{id} にナビゲート
        };

    return (
        <div className={`${styles['icon-box']}`}>
        <div className={`${styles['custom-node']}`} >
        <div className={`${styles['icon']} ${styles['icon-chart']}`} style={{ display: 'flex', alignItems: 'center', gap: '10px',width:'100%' }} >
        <button className={`${styles['menu-title']}`} onClick={handleClick} disabled={data.data.status === 'ready'}>{data.data.label}</button>
        <div className={`${styles[`icon-${data.data.status}`]}`}></div>
        {/* Handles for connecting edges */}
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
        </div>
        </div>
        </div>
    );
};

  const TableNode = (data: any) => {

    const navigate = useNavigate()
    const handleClick = () => {
        navigate(`/${data.id.replace(/_/g, '-')}`); // ボタンがクリックされたときに /{id} にナビゲート
        };

    return (
        <div className={`${styles['icon-box']}`}>
        <div className={`${styles['custom-node']}`}>
        <div className={`${styles['icon']} ${styles['icon-table']}`}style={{ display: 'flex', alignItems: 'center', gap: '10px',width:'100%' }}>
        <button className={`${styles['menu-title']}`} onClick={handleClick} disabled={data.data.status === 'ready'}>{data.data.label}</button>
        <div className={`${styles[`icon-${data.data.status}`]}`}></div>
        {/* Handles for connecting edges */}
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
        </div>
        </div>
      </div>
    );
  };

  // カスタムノードの登録
export const nodeTypes = {
  documentNode: DocumentNode,
  diagramNode: DiagramNode,
  tableNode: TableNode
};

export const initialNodes: Node[] = [
  { id: 'system_proposal', data: { label: 'システム概要書',status:'ready' }, position: { x: 0, y: 0 }, type:'documentNode'},
  { id: 'functional_requirement', data: { label: '機能要件書',status:'ready' }, position: { x: 300, y: 0 },type:'tableNode'},
  { id: 'non_functional_requirement', data: { label: '非機能要件書',status:'ready' }, position: { x: 300, y: 100 },type:'tableNode'},
  { id: 'work_flow', data: { label: '業務フロー図',status:'ready' }, position: { x: 300, y: 200 },type:'diagramNode'},
  { id: 'system_architecture', data: { label: 'システム構成図',status:'ready' }, position: { x: 600, y: 200 },type:'diagramNode'},
  { id: 'plugin_proposal', data: { label: 'プラグイン提案書',status:'ready' }, position: { x: 600, y: 0 },type:'tableNode'},
  { id: 'app_design', data: { label: 'アプリ設計書',status:'ready' }, position: { x: 600, y: 100 },type:'documentNode'},
  { id: 'management_field', data: { label: 'フィールド設計書',status:'ready' }, position: { x: 900, y: 0 },type:'tableNode'},
  { id: 'entity_relation', data: { label: 'ER図',status:'ready' }, position: { x: 1200, y: 0 },type:'diagramNode'},
  //{ id: 'customize_spec', data: { label: 'カスタマイズ仕様書',status:'ready' }, position: { x: 1200, y: 100 },type:'documentNode'},
  //{ id: '11', data: { label: 'サンプルアプリ',status:'in progress'}, position:{x: 1100, y:1050}}
];
