
import { type DefaultEdgeOptions, MarkerType,Edge} from '@xyflow/react';

export const defaultEdgeOptions: DefaultEdgeOptions = {
    animated: true,
    type:'step',
    style: { stroke: '#000' }, // エッジの色を黒に設定
    markerEnd:{
        type:MarkerType.ArrowClosed,
        color: '#000'
    }
};

export const edges: Edge[] = [
    { id: 'e1-2', source: 'system_proposal', target: 'functional_requirement', type: 'step' },
    //{ id: 'e8-10', source: 'management_field', target: 'customize_spec', type: 'step' },
    { id: 'e1-3', source: 'system_proposal', target: 'non_functional_requirement', type: 'step' },
    { id: 'e1-4', source: 'system_proposal', target: 'work_flow', type: 'step'},
    { id: 'e2-7', source: 'functional_requirement', target: 'system_architecture', type: 'step' },
    { id: 'e6-8', source: 'app_design', target: 'management_field', type: 'step' },
    { id: 'e6-7', source: 'app_design', target: 'system_architecture', type: 'step' },
    { id: 'e2-5', source: 'functional_requirement', target: 'plugin_proposal', type: 'step' },
    { id: 'e5-8', source: 'plugin_proposal', target: 'management_field', type: 'step' },
    { id: 'e2-6', source: 'functional_requirement', target: 'app_design', type: 'step' },
    { id: 'e8-9', source: 'management_field', target: 'entity_relation', type: 'step' },
  ]
