import React,{useState} from 'react';
import SummaryListView from '../components/summaryListView';
import Button from '../components/button';
import SelectionModal from '../components/importSelectionModal';

const Summary = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // モーダルの開閉状態

  // モーダルを閉じる
  const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  return (
    <div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
      <h1>議事録</h1>
      {/* インポートボタン */}
      <Button
        onClick={handleOpenModal} label='アップロード' styleType='blue'/>
      </div>
    <SummaryListView/>
    {/* モーダル表示 */}
    {isModalOpen && <SelectionModal onClose={handleCloseModal} />}
    <div>
    </div>
    </div>

  );
}

export default Summary;
